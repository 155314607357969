<template>
  <div>
    <ErrorAlert :errors="errors"></ErrorAlert>

    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="#67748e"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container fluid class="px-6 py-0">
      <!-- Totalizadores -->
      <v-row
        class="mt-4"
        v-if="
          quantidadeTitulosQuitados +
            quantidadeTitulosPendentes +
            quantidadeTitulosVencidos >
          0
        "
      >
        <div class="card-saldo ma-1" v-if="valorTitulosQuitados !== null">
          <p class="card-saldo__text" style="margin-bottom: 0">Quitados:</p>
          <p class="card-saldo__value" style="margin-bottom: 0">
            {{ valorTitulosQuitados | formatoMonetario }}
          </p>
        </div>
        <div class="card-saldo ma-1" v-if="quantidadeTitulosPendentes !== null">
          <p class="card-saldo__text" style="margin-bottom: 0">Pendentes:</p>
          <p class="card-saldo__value" style="margin-bottom: 0">
            {{ valorTitulosPendentes | formatoMonetario }}
          </p>
        </div>
        <div class="card-saldo ma-1" v-if="valorTitulosVencidos !== null">
          <p class="card-saldo__text" style="margin-bottom: 0">Atrasados:</p>
          <p class="card-saldo__value" style="margin-bottom: 0">
            {{ valorTitulosVencidos | formatoMonetario }}
          </p>
        </div>
        <div
          class="card-saldo ma-1"
          v-if="
            valorTitulosVencidos !== null &&
            valorTitulosQuitados !== null &&
            quantidadeTitulosPendentes !== null
          "
        >
          <p class="card-saldo__text" style="margin-bottom: 0">Total:</p>
          <p class="card-saldo__value" style="margin-bottom: 0">
            {{
              (valorTitulosVencidos +
                valorTitulosPendentes +
                valorTitulosQuitados)
                | formatoMonetario
            }}
          </p>
        </div>
      </v-row>
      <!-- modais -->
      <div id="dialog">
        <v-dialog v-model="deletado" persistent max-width="350">
          <v-card>
            <v-card-title class="headline">
              {{ $t("deletado_sucesso") }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                height="39"
                text
                @click="deletado = false"
                :loading="loading"
              >
                {{ $t("Confirmar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <!-- Modal confirmar delete -->
      <v-dialog
        v-model="confirmar"
        persistent
        max-width="350"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{ $t("Tem_certeza?") }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="confirmar = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click="deleteRecurso"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="recebimentoDetalhado"
        transition="dialog-bottom-transition"
        max-width="900"
        persistent
      >
        <v-card>
          <v-card-title class="headline">
            {{ $t("Devolucao") }} <br />
            <!-- <v-btn class="ma-2" color="info" text-color="white">
            {{ this.objetoAtual.descricao }}
          </v-btn> -->
            <v-divider></v-divider>
          </v-card-title>
          <v-card-text>
            <v-col cols="12" md="12">
              <template v-slot:heading>
                <div class="display-2 font-weight-light">
                  {{ $t("Listagem_de_produtos") }}
                </div>
              </template>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Procurar')"
                single-line
                hide-details
              ></v-text-field>

              <v-data-table
                :headers="headersItemPedido"
                :items="objetoAtual.items"
                :search="search"
                @click:row="handleClickPedido"
              >
              </v-data-table>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="recebimentoDetalhado = false"
            >
              {{ $t("Cancelar") }}
            </v-btn>
            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click="entregaParcial(objetoAtual)"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="formChangeProdutoPedido" persistent max-width="350">
        <v-card>
          <v-form @submit.prevent="changeProdutoPedido">
            <v-card-title class="headline">
              {{ $t("Adicionar_quantidade") }} <br />
              <v-btn
                :loading="loading"
                class="ma-2"
                color="green"
                text-color="white"
              >
                {{ this.produto.descricao }}
              </v-btn>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    type="number"
                    :label="$t('Quantidade')"
                    v-model.number="quantidade"
                    min="0"
                    :rules="[ruleQuantidadePendente]"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                :loading="loading"
                height="39"
                class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                @click="limpaFormProduto"
              >
                {{ $t("Cancelar") }}
              </v-btn>

              <v-btn
                :loading="loading"
                class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                height="39"
                @click.prevent="changeProdutoPedido(objetoAtual)"
                :disabled="confirmarQuantidadeDesabilitarBotao"
              >
                {{ $t("Adicionar") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <!-- Modal replicate -->
      <v-dialog
        v-model="confirmarReplicar"
        persistent
        max-width="350"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_acao?")
          }}</v-card-title>

          <v-col cols="12" v-if="nomeSingular !== 'PedidoConsumivel'">
            <v-text-field
              v-model="quantidade"
              :label="$t('Quantidade')"
              name="quantidade"
              type="number"
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-text-field>
          </v-col>
          <v-col cols="10" md="2" v-if="nomeSingular !== 'PedidoConsumivel'">
            <v-checkbox v-model="previsao">
              <template v-slot:label>
                {{ $t("previsao") }}
              </template>
            </v-checkbox>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarReplicar = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="replicateRecurso"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="confirmarDisponibilizacaoDocumento"
        persistent
        max-width="750"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_disponibilizacao_documentos?")
          }}</v-card-title>
          <v-card-subtitle>
            <v-ln>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  v-model="matricula"
                  :label="$t('Matricula')"
                  name="matricula"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  v-model="nome"
                  :label="$t('Nome')"
                  name="nome"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete
                  :label="$t('Clientes')"
                  :disabled="loading"
                  chips
                  multiple
                  deletable-chips
                  v-model="itemsCliente"
                  return-object
                  :items="clientes"
                  :item-text="
                    (item) => {
                      return item.razaoSocial;
                    }
                  "
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete
                  :label="$t('PostoTrabalho')"
                  :disabled="loading"
                  chips
                  dens
                  multiple
                  deletable-chips
                  v-model="itemsPostoTrabalho"
                  return-object
                  :items="postoTrabalhos"
                  :item-text="(item) => item.descricao"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-autocomplete>
              </v-col>
            </v-ln>
          </v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarDisponibilizacaoDocumento = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="disponibilizarDocumentoFuncionarios"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pedido Separacao -->
      <v-dialog
        v-model="confirmarSeparacao"
        persistent
        max-width="450"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_separacao_do_pedido?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarSeparacao = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="separacao"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pedido Disponibilizacao -->
      <v-dialog
        v-model="confirmarDisponibilizacao"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_disponibilizacao_do_pedido?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarDisponibilizacao = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="disponibilizar"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pedido confirmarTroca -->
      <v-dialog
        v-model="confirmarTroca"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_troca_Pedido?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarTroca = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="trocar"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pedido Retirado Terceiro -->
      <v-dialog
        v-model="confirmarRetiradoTerceiro"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_retirada_do_pedido?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarRetiradoTerceiro = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="retiradaTerceiro"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pedido Entrega -->
      <v-dialog
        v-model="confirmarEntrega"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_entrega_do_pedido?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarEntrega = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="entregaPendente"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pedido Recebimento -->
      <v-dialog
        v-model="confirmarRetirada"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_retirada_do_pedido?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarRetirada = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="retirar"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pedido Recebimento -->
      <v-dialog
        v-model="confirmarRecebimento"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_recebimento_do_pedido?")
          }}</v-card-title>
          <v-card-body v-if="this.nomeSingular === 'PedidoUniforme'">
            <v-textarea
              v-model="objetoAtual.observacaoRecebimento"
              :label="$t('Detalhes_entrega')"
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-textarea>
          </v-card-body>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarRecebimento = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="receber"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pedido Entrega total -->
      <v-dialog
        v-model="confirmarEntregaTotal"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_entrega_total_do_pedido?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="confirmarEntregaTotal = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="entregaTotal"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalConfirmaValidacaoFinanceira01"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_validacao?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalConfirmaValidacaoFinanceira01 = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="validacaoFinanceira01"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalConfirmaValidacaoFinanceira02"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_validacao?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalConfirmaValidacaoFinanceira02 = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="validacaoFinanceira02"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalConfirmaAprovacao"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_aprovacao?")
          }}</v-card-title>

          <v-card-subtitle v-if="nomeSingular === 'ContaVinculadaMovimentacao'">
            <v-spacer></v-spacer>
            <v-col cols="12" class="d-flex">
              <CampoData
                :label="$t('Data')"
                @date="dataAprovacao = $event"
                :key="idDialog"
              />
            </v-col>
          </v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalConfirmaAprovacao = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="aprovar"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalConfirmaGeracaoDocumento"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_geracao_documento_fiscal?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalConfirmaGeracaoDocumento = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="gerarDocumento"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalConfirmaExecucaoServico"
        persistent
        max-width="470"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_registro_execucao?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalConfirmaExecucaoServico = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="registroExecucaoServico"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="modalGuias" max-width="800" class="dialog-deletado">
        <v-card>
          <v-card-title class="headline">{{ $t("Guias") }}</v-card-title>
          <v-data-table
            :headers="headerGuias"
            :items="objetoAtual.guias"
            class="elevation-1"
            disable-pagination
          >
            <template v-slot:[`item.valor`]="{ item }">
              <span>{{ item.valor | formatoMonetario }}</span>
            </template>

            <template v-slot:[`item.vencimento`]="{ item }">
              <span>{{ item.vencimento | dataParaLeitura }}</span>
            </template>

            <template v-slot:[`item.statusCobranca`]="{ item }">
              {{ $t(item.statusCobranca) }}
            </template>

            <template v-slot:[`item.situacao`]="{ item }">
              {{ $t(item.situacao) }}
            </template>

            <template v-slot:[`item.valorDeclarado`]="{ item }">
              <span>{{ item.valorDeclarado | formatoMonetario }}</span>
            </template>

            <template v-slot:[`item.actionsGuias`]="{ item }">
              <v-icon
                small
                name="printBtn"
                id="print"
                :title="$t('Imprimir')"
                class="mr-2 ma-1"
                @click="printGtv(item)"
                :loading="loading"
                >ni-collection</v-icon
              >
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              height="39"
              text
              @click="modalGuias = false"
            >
              {{ $t("Fechar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalEventosTv"
        max-width="1200"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{ $t("Eventos") }} </v-card-title>
          <v-card-subtitle>
            <v-spacer></v-spacer>
            <br />
            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="confirmaNovoEventoTv()"
              :loading="loading"
            >
              {{ $t("novo_registro") }}
            </v-btn>
          </v-card-subtitle>
          <v-data-table
            :headers="headerEventosTv"
            :items="eventosTv"
            class="elevation-1"
            disable-pagination
          >
            <template v-slot:[`item.dtInclusao`]="{ item }">
              <span>{{ item.dtInclusao | dataHoraParaLeitura }}</span>
            </template>
            <template v-slot:[`item.data`]="{ item }">
              <span>{{ item.data | dataHoraParaLeitura }}</span>
            </template>

            <template v-slot:[`item.exportacaoRealizada`]="{ item }">
              <span>{{
                item.exportacaoRealizada ? $t("sim") : $t("nao")
              }}</span>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-icon
                @click="openEditarModalEventoTv(item)"
                icon
                elevation="0"
                :ripple="false"
                v-if="editar"
                small
                name="editBtn"
                id="edit"
                :title="$t('Editar')"
                class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                color="#67748e"
                >ni-ruler-pencil</v-icon
              >
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              height="39"
              text
              @click="modalEventosTv = false"
            >
              {{ $t("Fechar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalEventoTv"
        max-width="800"
        class="dialog-deletado"
        persistent
      >
        <v-card>
          <v-card-title class="headline">{{ $t("Evento") }} </v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col cols="12" md="12">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Ocorrencia") }}
                </v-span>
                <v-autocomplete
                  id="selecionaOcorrencia"
                  outlined
                  v-model="eventoTvAtual.ocorrencia"
                  :items="ocorrenciasTvOpcoes"
                  :item-text="(item) => item.descricao"
                  :label="$t('Ocorrencia')"
                  return-object
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="12">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Evento") }}
                </v-span>
                <v-autocomplete
                  id="selecionaEvento"
                  outlined
                  v-model="eventoTvAtual.evento"
                  :items="eventosTvOpcoes"
                  :item-text="(item) => item.descricao"
                  :label="$t('Evento')"
                  return-object
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Data") }}
                </v-span>
                <CampoDataHora
                  @date="eventoTvAtual.data = $event"
                  name="data"
                  :label="$t('Data')"
                  :payload="eventoTvAtual.data"
                  :key="idDialog"
                />
              </v-col>

              <v-col cols="12" md="12">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Detalhes") }}
                </v-span>
                <v-textarea
                  v-model="eventoTvAtual.detalhes"
                  :payload="eventoTvAtual.detalhes"
                  :label="$t('Detalhes')"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
          </v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              v-if="
                eventoTvAtual.exportacaoRealizada === undefined ||
                eventoTvAtual.exportacaoRealizada === false
              "
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="registroNovoEventoTv()"
              :loading="loading"
            >
              {{ $t("Enviar") }}
            </v-btn>

            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              height="39"
              text
              @click="modalEventoTv = false"
              :loading="loading"
            >
              {{ $t("Fechar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalGerarRoteiroModelo"
        max-width="800"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("RoteiroModelos")
          }}</v-card-title>
          <v-card-subtitle>
            <v-col cols="12" md="12">
              <v-autocomplete
                id="selecionaRoteiroModelo"
                outlined
                v-model="roteiroModelo"
                :items="roteiroModelos"
                :item-text="(item) => item.descricao"
                :label="$t('Modelo')"
                return-object
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              >
              </v-autocomplete>
            </v-col>
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="gerarRoteirosModelo()"
              :loading="loading"
            >
              {{ $t("Confirmar") }}
            </v-btn>
            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              height="39"
              text
              @click="modalGerarRoteiroModelo = false"
              :loading="loading"
            >
              {{ $t("Fechar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal transferencia empresa -->
      <v-dialog
        v-model="modalTransferenciaEmpresa"
        persistent
        max-width="350"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Transferencia_empresa")
          }}</v-card-title>

          <v-col cols="12" class="d-flex">
            <v-text-field
              v-model="objTransferenciaEmpresa.matriculaAtual"
              :label="$t('Matricula_atual')"
              name="Matricula_atual"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-text-field
              v-model="objTransferenciaEmpresa.matriculaNova"
              :label="$t('Matricula_nova')"
              name="Matricula_nova"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex">
            <CampoData
              :payload="objTransferenciaEmpresa.dataTransferencia"
              label="Data_transferencia"
              @date="objTransferenciaEmpresa.dataTransferencia = $event"
              name="Data_transferencia"
            >
            </CampoData>
          </v-col>
          <v-col cols="12">
            {{ $t("Destino") }}
            <CamposTipoNregistro
              :nRegistro="objTransferenciaEmpresa.nregistroDestino"
              :tipo="objTransferenciaEmpresa.nregistroDestino"
              @nRegistro="objTransferenciaEmpresa.nregistroDestino = $event"
              @tipo="objTransferenciaEmpresa.nregistroDestino = $event"
              :disabled="false"
            ></CamposTipoNregistro>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalTransferenciaEmpresa = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="transferenciaEmpresa()"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal seleciona arquivo-->
      <v-dialog v-model="modalImportArquivo" max-width="550">
        <v-card>
          <v-card-title>
            {{ $t("Selecione_arquivo") }}
          </v-card-title>

          <v-card-actions>
            <input type="file" ref="doc" />
            <div>{{ statusReader }}</div>
          </v-card-actions>

          <v-btn
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            text
            @click="importExternal()"
            :loading="loading"
          >
            {{ $t("Confirmar") }}
          </v-btn>
          <v-btn
            height="39"
            class="ma-1 font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            text
            @click="modalImportArquivo = false"
            :loading="loading"
          >
            {{ $t("Cancelar") }}
          </v-btn>
          <v-btn
            v-if="this.nomeSingular === 'FuncionarioMovimentacoes'"
            height="39"
            class="ma-1 font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            onclick="window.location ='https://app-objetivar-img.s3.amazonaws.com/system_files/Cajupe_Modelo_Imp_Movimentacoes.csv';"
            :loading="loading"
          >
            {{ $t("Arquivo_modelo") }}
          </v-btn>
          <v-btn
            v-if="this.nomeSingular === 'Funcionario'"
            height="39"
            class="ma-1 font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            onclick="window.location ='https://app-objetivar-img.s3.amazonaws.com/system_files/Cajupe_Modelo_Imp_Admissao.csv';"
            :loading="loading"
          >
            {{ $t("Arquivo_modelo") }}
          </v-btn>
          <v-btn
            v-if="this.nomeSingular === 'FuncionarioAfastamento'"
            height="39"
            class="ma-1 font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            onclick="window.location ='https://app-objetivar-img.s3.amazonaws.com/system_files/Cajupe_Modelo_Imp_Afastamentos.csv';"
            :loading="loading"
          >
            {{ $t("Arquivo_modelo") }}
          </v-btn>
          <v-btn
            v-if="this.nomeSingular === 'BeneficioConvenioOptante'"
            height="39"
            class="ma-1 font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            onclick="window.location ='https://app-objetivar-img.s3.amazonaws.com/system_files/Cajupe_Modelo_Imp_Optante_Beneficio.csv';"
            :loading="loading"
          >
            {{ $t("Arquivo_modelo") }}
          </v-btn>
        </v-card>
      </v-dialog>

      <v-dialog v-model="modalPedidosPendentes" class="dialog-deletado">
        <v-card>
          <v-card-title class="headline">{{ $t("Pedidos") }}</v-card-title>
          <v-data-table
            :headers="headerPedidos"
            :items="pedidosPendentes"
            class="elevation-1"
            disable-pagination
          >
            <template v-slot:[`item.data`]="{ item }">
              <span>{{ item.data | dataParaLeitura }}</span>
            </template>

            <template v-slot:[`item.situacao`]="{ item }">
              {{ $t(item.situacao) }}
            </template>
            <template v-slot:[`item.classificacao`]="{ item }">
              {{ $t(item.classificacao) }}
            </template>

            <template v-slot:[`item.valorDeclarado`]="{ item }">
              <span>{{ item.valorDeclarado | formatoMonetario }}</span>
            </template>

            <template v-slot:[`item.janelaOrigemInicio`]="{ item }">
              {{ item.janelaOrigemInicio | horaParaLeitura }}
            </template>
            <template v-slot:[`item.janelaOrigemFinal`]="{ item }">
              {{ item.janelaOrigemFinal | horaParaLeitura }}
            </template>
            <template v-slot:[`item.janelaDestinoInicio`]="{ item }">
              {{ item.janelaDestinoInicio | horaParaLeitura }}
            </template>
            <template v-slot:[`item.janelaDestinoFinal`]="{ item }">
              {{ item.janelaDestinoFinal | horaParaLeitura }}
            </template>

            <template v-slot:[`item.actionsPedidos`]="{ item }">
              <v-icon
                small
                name="printBtn"
                id="print"
                :title="$t('Roteirizar')"
                class="mr-2 ma-1"
                @click="showModalRoteirizarPedido(item)"
                :loading="loading"
                >ni-button-play</v-icon
              >
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              height="39"
              text
              @click="modalPedidosPendentes = false"
            >
              {{ $t("Fechar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="modalRoteirizarPedido"
        class="dialog-deletado"
        max-width="600"
      >
        <v-card>
          <v-card-title class="headline">
            <v-btn class="ma-2">
              {{ $t("Pedido") + " -  #" + pedidoAtual.id }}
            </v-btn>
            <br />
            {{ $t("Origem") + ": " + pedidoAtual.origem.descricao }}<br />
            {{
              pedidoAtual.origem.logradouro +
              ", " +
              pedidoAtual.origem.numero +
              " - " +
              pedidoAtual.origem.bairro
            }}
            <br />
            {{
              pedidoAtual.origem.cidade.nome +
              "/" +
              pedidoAtual.origem.cidade.estado.sigla
            }}<br />
            {{ $t("Janela") + ": " }}
            {{ pedidoAtual.janelaOrigemInicio | horaParaLeitura }} a
            {{ pedidoAtual.janelaOrigemFinal | horaParaLeitura }}

            <div v-if="pedidoAtual.classificacao !== 'ASSISTENCIA'">
              <br />

              {{ $t("Destino") + ": " + pedidoAtual.destino.descricao }}<br />
              {{
                pedidoAtual.destino.logradouro +
                ", " +
                pedidoAtual.destino.numero +
                " - " +
                pedidoAtual.destino.bairro
              }}
              <br />
              {{
                pedidoAtual.destino.cidade.nome +
                "/" +
                pedidoAtual.destino.cidade.estado.sigla
              }}<br />
              {{ $t("Janela") + ": " }}
              {{ pedidoAtual.janelaDestinoInicio | horaParaLeitura }} a
              {{ pedidoAtual.janelaDestinoFinal | horaParaLeitura }}
            </div>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <div class="ma-4 font-weight-light rounded">
                  <CampoHorario
                    @date="novoTrajeto.previsaoAtendimentoOrigem = $event"
                    :label="$t('previsaoAtendimentoOrigem')"
                    v-model="novoTrajeto.previsaoAtendimentoOrigem"
                    :key="keyModal"
                  />
                  <br />
                  <CampoHorario
                    v-if="pedidoAtual.classificacao !== 'ASSISTENCIA'"
                    @date="novoTrajeto.previsaoAtendimentoDestino = $event"
                    :label="$t('previsaoAtendimentoDestino')"
                    v-model="novoTrajeto.previsaoAtendimentoDestino"
                    :key="keyModal"
                  />
                  <br />

                  <v-autocomplete
                    outlined
                    v-model="novoTrajeto.roteiro"
                    :items="roteirosDisponiveis"
                    :item-text="
                      (item) => item.roteiro + ' - ' + item.operador.nome
                    "
                    :label="$t('Roteiro')"
                    return-object
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                    :key="keyModal"
                  >
                  </v-autocomplete>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="modalRoteirizarPedido = false"
            >
              {{ $t("Cancelar") }}
            </v-btn>

            <v-btn
              type="submit"
              :disabled="
                novoTrajeto.roteiro.id === 0 ||
                novoTrajeto.previsaoAtendimentoOrigem === '' ||
                novoTrajeto.previsaoAtendimentoDestino === ''
              "
              @click="roteirizarPedido"
              class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >
              {{ $t("Adicionar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal cadastrado com sucesso -->
      <v-dialog v-model="dialogSuccesEdit" max-width="500">
        <div align="center">
          <v-card>
            <v-card-title>
              <div class="font-weight-bold text-h5 text-typo mb-2">
                {{ $t("editado_sucesso") }}
              </div>
            </v-card-title>
            <v-btn
              height="39"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              @click="dialogSuccesEdit = false"
              id="btn-modal-sucesso-nao"
            >
              {{ $t("Ok") }}
            </v-btn>
          </v-card>
        </div>
      </v-dialog>

      <v-dialog v-model="dialogSuccesInsert" max-width="500">
        <div align="center">
          <v-card>
            <v-card-title>
              <div class="font-weight-bold text-h5 text-typo mb-2">
                {{ $t("cadastrado_sucesso") }}
              </div>
            </v-card-title>
            <v-btn
              height="39"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              @click="dialogSuccesInsert = false"
              id="btn-modal-sucesso-nao"
            >
              {{ $t("Ok") }}
            </v-btn>
          </v-card>
        </div>
      </v-dialog>

      <v-dialog v-model="dialogEdit" persistent max-width="100%">
        <component
          :is="editComponent"
          v-if="editComponent"
          :itemAtual="recursoAtual"
          :recurso="recursoAtual"
          @submitSuccess="onSubmitSuccessEdit"
          @submitError="onSubmitError"
          @closeModalEdit="closeEditarModal"
        />
      </v-dialog>

      <v-dialog v-model="dialogInsert" persistent max-width="100%">
        <component
          :is="insertComponent"
          v-if="insertComponent"
          @submitSuccessInsert="onSubmitSuccessInsert"
          @submitErrorInsert="onSubmitErrorInsert"
          @closeModalInsert="closeInsertModal"
        />
      </v-dialog>

      <v-row class="mt-8">
        <v-div>
          <v-row>
            <slot name="upload"> </slot>

            <v-btn
              v-if="this.nomeSingular === 'RoteiroTrajeto'"
              :loading="loading"
              @click="showPedidosPendentes()"
              v-can="'ROLE_Pedido_List'"
              id="btn--PedidoPendentes"
              :ripple="false"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
            >
              <v-icon left dark> mdi-plus-circle </v-icon>
              {{
                $t("PedidosPendentes") +
                " (" +
                this.quantidadePedidosPendentes +
                ")"
              }}
            </v-btn>

            <v-btn
              v-if="botaoCadastro"
              :loading="loading"
              @click="openInsertModal()"
              v-can="'ROLE_' + this.nomeSingular + '_Insert'"
              id="btn--cadastro"
              :ripple="false"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
            >
              <v-icon left dark> mdi-plus-circle </v-icon>
              {{ $t(this.stringCadastro) }}
            </v-btn>
            <v-btn
              v-if="
                (nomeSingular === 'Funcionario' ||
                  nomeSingular === 'FuncionarioResumed') &&
                !possuiDocumentacaoAcessoria()
              "
              :loading="loading"
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click="openInsertModalAdmissaoResumida()"
              v-can="'ROLE_' + this.nomeSingular + '_Insert'"
            >
              <v-icon left dark> mdi-plus-circle </v-icon>
              {{ $t("Admissao_resumida") }}
            </v-btn>
            <v-btn
              v-if="botaoTransferenciaEmpresaFuncionario"
              :loading="loading"
              @click="confirmaTransferenciaEmpresa()"
              v-can="'ROLE_' + this.nomeSingular + '_Insert'"
              id="btn--transferenciaEmpresa"
              :ripple="false"
              class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
            >
              <v-icon left dark> mdi-bank-transfer-out </v-icon>
              {{ $t("Transferencia_empresa") }}
            </v-btn>
            <v-btn
              @click="exportCSV()"
              :loading="loading"
              v-can="'ROLE_' + this.nomeSingular + '_Export'"
              id="btn--exportacao"
              :ripple="false"
              class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
            >
              <v-icon left dark> ni-cloud-download-95 </v-icon>
              {{ $t("Exportar") }}
            </v-btn>
            <v-btn
              v-if="isSearching"
              @click="exportPDF()"
              :loading="loading"
              v-can="'ROLE_' + this.nomeSingular + '_Export_Pdf'"
              id="btn--exportacao"
              :ripple="false"
              class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
            >
              <v-icon left dark> ni-cloud-download-95 </v-icon>
              {{ $t("PDF") }}
            </v-btn>
            <v-btn
              v-if="botaoImport"
              :loading="loading"
              @click.stop="openModalImportArquivo()"
              id="btn-import-csv"
              class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
            >
              <v-icon left> ni-cloud-upload-96 </v-icon>
              {{ $t("Importar") }}
            </v-btn>
            <v-btn
              v-if="botaoImportContaContabil"
              :loading="loading"
              @click.stop="openModalImportContaContabil()"
              id="btn-import-csv"
              class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
            >
              <v-icon left> ni-cloud-upload-96 </v-icon>
              {{ $t("Importar_conta_contabil") }}
            </v-btn>
            <v-btn
              v-if="botaoGerarRoteiroModelo && pesquisaDataAtualTv"
              :loading="loading"
              @click="confirmaGerarRoteirosModelo()"
              v-can="'ROLE_RoteiroModelo_Route'"
              id="btn--GerarRoteiroModelo"
              :ripple="false"
              class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
            >
              <v-icon left dark> ni-cloud-upload-96 </v-icon>
              {{ $t("RoteiroModelos") }}
            </v-btn>
          </v-row>
        </v-div>
        <v-col cols="12">
          <template>
            <div>
              <v-card class="card-shadow border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-row class="mt-1">
                      <v-col cols="12" md="5" v-if="buscaSimplificada">
                        <v-text-field
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="39"
                          v-model="search"
                          :label="$t('Procurar')"
                        >
                          <template slot="prepend-inner">
                            <v-icon color="#adb5bd" size=".875rem"
                              >fas fa-search</v-icon
                            >
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="8" v-if="pesquisaFuncionario">
                        <v-autocomplete
                          :label="$t('Funcionario')"
                          :disabled="loading"
                          chips
                          dense
                          multiple
                          deletable-chips
                          v-model="itemsFuncionario"
                          return-object
                          :items="funcionarios"
                          :item-text="
                            (item) => item.matricula + ' - ' + item.nomeSocial
                          "
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          flat
                          filled
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="10" v-if="pesquisaPessoa">
                        <v-autocomplete
                          :label="$t('Pessoas')"
                          :disabled="loading"
                          chips
                          multiple
                          deletable-chips
                          v-model="itemsPessoa"
                          return-object
                          :items="pessoas"
                          :item-text="(item) => item.cpf + ' - ' + item.nome"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="4" v-if="pesquisaBeneficioConvenio">
                        <v-autocomplete
                          :label="$t('Convenio')"
                          :disabled="loading"
                          chips
                          multiple
                          deletable-chips
                          v-model="itemsBeneficioConvenio"
                          return-object
                          :items="beneficioConvenios"
                          :item-text="(item) => item.descricao"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaVencimento">
                        <CampoData
                          :disabled="loading"
                          :payload="vencimentoInicio"
                          label="Vencimento_inicio"
                          @date="handleDataInicioVencimentoChange"
                        >
                        </CampoData>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaVencimento">
                        <CampoData
                          :disabled="loading"
                          :payload="vencimentoFim"
                          label="Vencimento_fim"
                          @date="handleDataFinalVencimentoChange"
                        >
                        </CampoData>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaCompetencia">
                        <CampoCompetencia
                          label="Competencia"
                          :payload="competencia"
                          @date="handleCompetenciaChange"
                          :disabled="!loading"
                        >
                        </CampoCompetencia>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaPeriodo">
                        <CampoData
                          :disabled="loading"
                          :payload="inicioPeriodo"
                          label="Inicio_do_periodo"
                          @date="handleDataInicioPeriodoChange"
                        >
                        </CampoData>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaPeriodo">
                        <CampoData
                          :disabled="loading"
                          :payload="encerramentoPeriodo"
                          label="Final_do_periodo"
                          @date="handleDataEncerramentoPeriodoChange"
                        >
                        </CampoData>
                      </v-col>

                      <v-col cols="12" xs="4" sm="6" v-if="pesquisaDetalhe">
                        <v-text-field
                          v-model="detalhe"
                          :label="$t('Descricao')"
                          name="detalhe"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaMatricula">
                        <v-text-field
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          v-model="matricula"
                          :label="$t('Matricula')"
                          name="matricula"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" v-if="pesquisaNome">
                        <v-text-field
                          v-model="nome"
                          :label="$t('Nome')"
                          name="nome"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3" v-if="pesquisaData">
                        <CampoData
                          :disabled="loading"
                          :payload="data"
                          label="Data"
                          @date="handleDataChange"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        >
                        </CampoData>
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                        v-if="pesquisaDataAtualTv || pesquisaDataSeguinteTv"
                      >
                        <CampoData
                          :disabled="loading"
                          :payload="data"
                          label="Data"
                          @date="handleDataChange"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        >
                        </CampoData>
                      </v-col>

                      <v-col cols="12" md="3" v-if="pesquisaContaFinanceira">
                        <v-autocomplete
                          :label="$t('Conta_financeira')"
                          :disabled="loading"
                          chips
                          multiple
                          deletable-chips
                          v-model="itemsContaFinanceira"
                          return-object
                          :items="contasFinanceiras"
                          :item-text="
                            (item) => {
                              return item.descricao;
                            }
                          "
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="3" v-if="pesquisaFornecedor">
                        <v-autocomplete
                          :label="$t('Fornecedor')"
                          :disabled="loading"
                          chips
                          multiple
                          deletable-chips
                          v-model="itemsFornecedor"
                          return-object
                          :items="fornecedores"
                          :item-text="
                            (item) => {
                              return item.razaoSocial;
                            }
                          "
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="3" v-if="pesquisaCliente">
                        <v-autocomplete
                          :label="$t('Clientes')"
                          :disabled="loading"
                          chips
                          multiple
                          deletable-chips
                          v-model="itemsCliente"
                          return-object
                          :items="clientes"
                          :item-text="
                            (item) => {
                              return item.razaoSocial;
                            }
                          "
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="3" v-if="pesquisaPostoTrabalho">
                        <v-autocomplete
                          :label="$t('PostoTrabalho')"
                          :disabled="loading"
                          chips
                          dens
                          multiple
                          deletable-chips
                          v-model="itemsPostoTrabalho"
                          return-object
                          :items="postoTrabalhos"
                          :item-text="(item) => item.descricao"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaStatusFinanceiro">
                        <v-select
                          v-model="statusFinanceiro"
                          :items="[
                            { value: 'PENDENTE', text: $t('PENDENTE') },
                            { value: 'QUITADO', text: $t('QUITADO') },
                            { value: '', text: $t('Todos') },
                          ]"
                          item-text="text"
                          item-value="value"
                          :label="$t('Status')"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                        v-if="pesquisaStatusPedidoUniforme"
                      >
                        <v-select
                          v-model="statusFinanceiro"
                          :items="[
                            { value: 'PENDENTE', text: $t('PENDENTE') },
                            { value: 'SEPARACAO', text: $t('SEPARACAO') },
                            {
                              value: 'DISPONIBILIZADO',
                              text: $t('DISPONIBILIZADO'),
                            },
                            { value: 'RETIRADO', text: $t('RETIRADO') },
                            { value: 'RECEBIDO', text: $t('RECEBIDO') },
                            {
                              value: 'DEVOLUCAOTOTAL',
                              text: $t('DEVOLUCAOTOTAL'),
                            },
                            {
                              value: 'DEVOLUCAOPARCIAL',
                              text: $t('DEVOLUCAOPARCIAL'),
                            },
                            { value: 'CANCELADO', text: $t('CANCELADO') },
                            { value: null, text: $t('Todos') },
                          ]"
                          item-text="text"
                          item-value="value"
                          :label="$t('Status')"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaFaturado">
                        <v-select
                          v-model="faturado"
                          :items="[
                            { value: true, text: $t('PENDENTE') },
                            { value: false, text: $t('FATURADO') },
                            { value: null, text: $t('Todos') },
                          ]"
                          item-text="text"
                          item-value="value"
                          :label="$t('Status')"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="2" v-if="pesquisaValor">
                        <v-text-field-money
                          v-model="valorFinanceiro"
                          :disabled="loading"
                          v-bind:label="$t('Valor')"
                          v-bind:properties="{
                            prefix: 'R$',
                            readonly: false,
                            disabled: false,
                            outlined: false,
                            clearable: true,
                            rules: required,
                          }"
                          v-bind:options="{
                            locale: 'pt-BR',
                            length: 12,
                            precision: 2,
                            empty: '0',
                          }"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                        v-if="pesquisasituacaoMovimentacaoContaVinculada"
                      >
                        <v-select
                          v-model="situacaoMovimentacaoContaVinculada"
                          :items="[
                            { value: 'PENDENTE', text: $t('PENDENTE') },
                            { value: 'RESGATADO', text: $t('RESGATADO') },
                            {
                              value: 'CANCELADO',
                              text: $t('CANCELADO'),
                            },
                            { value: null, text: $t('Todos') },
                          ]"
                          item-text="text"
                          item-value="value"
                          :label="$t('Status')"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="3" v-if="pesquisaContaVinculada">
                        <v-autocomplete
                          :label="$t('contaVinculada')"
                          :disabled="loading"
                          chips
                          deletable-chips
                          v-model="contaVinculada"
                          return-object
                          :items="contasVinculada"
                          :item-text="
                            (item) => {
                              return item.descricao;
                            }
                          "
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="4" v-if="pesquisaContaBancaria">
                        <v-text-field
                          v-model="contaBancaria"
                          :label="$t('ContaBancaria')"
                          name="contaBancaria"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        >
                        </v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                        id="exportar"
                        v-if="!buscaSimplificada"
                      >
                        <v-btn
                          @click="filtrar()"
                          :loading="loading"
                          id="btn--filtrar"
                          :ripple="false"
                          class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                          height="39"
                        >
                          <v-icon left dark> ni-zoom-split-in </v-icon>
                          {{ $t("Filtrar") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-card-text class="px-0 py-0">
                  <v-data-table
                    :headers="headersFiltrados"
                    :items="recursos"
                    :search="search"
                    class="table"
                    :page.sync="page"
                    hide-default-footer
                    @page-count="pageCount = $event"
                    :items-per-page="isSearching ? 200 : pageSize"
                    mobile-breakpoint="0"
                    :show-expand="expandableProp"
                    :single-expand="singleExpandProp"
                    :item-class="isStatusComum"
                    @current-items="filteredItems = $event"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        v-can="'ROLE_' + nomeSingular + '_List'"
                        @click="openEditarModal(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        v-if="editar"
                        small
                        name="editBtn"
                        id="edit"
                        :title="$t('Editar')"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                        >ni-ruler-pencil</v-icon
                      >

                      <v-icon
                        v-if="nomeSingular !== 'ContaFinanceira'"
                        small
                        name="replicarBtn"
                        id="edit"
                        :title="$t('Replicar')"
                        v-can="'ROLE_' + nomeSingular + '_Replicate'"
                        @click="confirmaReplicate(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-curved-next
                      </v-icon>

                      <v-icon
                        v-can="'ROLE_' + nomeSingular + '_Delete'"
                        small
                        :title="$t('Excluir')"
                        name="deleteBtn"
                        @click="confirmaDelete(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-fat-remove
                      </v-icon>
                      <v-icon
                        small
                        name="printBtn"
                        class="mr-2"
                        :label="$t('Imprimir')"
                        :title="$t('Imprimir')"
                        v-can="'ROLE_' + nomeSingular + '_Print'"
                        @click="printSingle(item.id, '')"
                        color="#67748e"
                        >ni-collection</v-icon
                      >
                      <v-icon
                        v-if="nomeSingular === 'ModeloDocumentacaoAcessoria'"
                        small
                        name="disponibilizarBtn"
                        id="edit"
                        :title="$t('Disponibilizar')"
                        v-can="'ROLE_' + nomeSingular + '_MakeEveryone'"
                        @click="confirmaDisponibilizacaoDocumento(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-curved-next
                      </v-icon>
                      <v-icon
                        v-if="
                          nomeSingular ===
                            'FuncionarioAfastamentoSolicitacao' &&
                          item.status === 'PENDENTE'
                        "
                        small
                        name="aprovarBtn"
                        id="edit"
                        :title="$t('Aprovar')"
                        v-can="'ROLE_' + nomeSingular + '_Aprovar'"
                        @click="confirmaAprovacao(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-curved-next
                      </v-icon>
                      <v-icon
                        v-if="
                          nomeSingular === 'ContaVinculadaMovimentacao' &&
                          item.situacao === 'PENDENTE'
                        "
                        small
                        name="aprovarBtn"
                        id="edit"
                        :title="$t('Aprovar')"
                        v-can="'ROLE_' + nomeSingular + '_Aprovar'"
                        @click="confirmaAprovacao(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-curved-next
                      </v-icon>

                      <v-icon
                        v-if="
                          nomeSingular === 'RoteiroTrajeto' &&
                          item.guias.length === 0
                        "
                        small
                        name="gerarGtv"
                        id="gerarGtv"
                        :title="$t('Gerar_documento')"
                        @click="confirmaGeracaoDocumento(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-curved-next
                      </v-icon>
                      <v-icon
                        v-if="
                          (nomeSingular === 'RoteiroTrajeto' ||
                            nomeSingular === 'Suprimento') &&
                          item.guias.length > 0
                        "
                        small
                        name="gerarGtv"
                        id="gerarGtv"
                        :title="$t('Imprimir')"
                        @click="listGuias(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-collection
                      </v-icon>

                      <v-icon
                        v-if="
                          nomeSingular === 'RoteiroTrajeto' &&
                          possuiLogisticaTransporte()
                        "
                        small
                        name="EventosTv"
                        id="eventosTv"
                        :title="$t('Eventos')"
                        @click="listEventosTv(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-delivery-fast
                      </v-icon>
                      <v-icon
                        v-if="
                          nomeSingular === 'FaturamentoVariaveisServico' &&
                          item.periodicidade !== null &&
                          item.periodicidade !== undefined &&
                          !item.executado
                        "
                        small
                        name="registrarExecucaoBtn"
                        id="edit"
                        :title="$t('Executar')"
                        v-can="'ROLE_' + nomeSingular + '_Update'"
                        @click="confirmaExecucaoServico(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        color="#67748e"
                      >
                        ni-check-bold
                      </v-icon>
                    </template>
                    <template v-slot:no-data>
                      <v-alert :value="true" color="error" icon="mdi-alert">
                        {{ $t("Nenhum dado encontrado") }}
                      </v-alert>
                    </template>
                    <template v-slot:[`item.imagem`]="{ item }">
                      <ShowImage :url="item.imagem">
                        <v-avatar color="primary" size="50"
                          ><v-img
                            contain
                            max-height="151"
                            max-width="250"
                            :src="item.imagem"
                          ></v-img
                        ></v-avatar>
                      </ShowImage>
                    </template>
                    <template v-slot:[`item.email`]="{ item }">
                      {{ item.email }}
                    </template>
                    <template v-slot:[`item.tipo`]="{ item }">
                      {{ $t(item.tipo) }}
                    </template>
                    <template v-slot:[`item.tipoCliente`]="{ item }">
                      {{ $t(item.tipoCliente) }}
                    </template>
                    <!-- Formatações pra valores -->

                    <template v-slot:[`item.valor`]="{ item }">
                      <span>{{ item.valor | formatoMonetario }}</span>
                    </template>
                    <template v-slot:[`item.saldoLogico`]="{ item }">
                      <span>{{ item.saldoLogico | formatoMonetario }}</span>
                    </template>
                    <template v-slot:[`item.saldoFisico`]="{ item }">
                      <span>{{ item.saldoFisico | formatoMonetario }}</span>
                    </template>
                    <template v-slot:[`item.horaCorte`]="{ item }">
                      <span>{{ item.horaCorte | horaParaLeitura }}</span>
                    </template>
                    <template v-slot:[`item.ultimaConexao`]="{ item }">
                      <span>{{
                        item.ultimaConexao | dataHoraParaLeitura
                      }}</span>
                    </template>
                    <template v-slot:[`item.valorTotal`]="{ item }">
                      <span>{{ item.valorTotal | formatoMonetario }}</span>
                    </template>

                    <template v-slot:[`item.statusConectividade`]="{ item }">
                      <span>{{ $t(item.statusConectividade) }}</span>
                    </template>

                    <template v-slot:[`item.faturado`]="{ item }">
                      <span>{{
                        item.faturado ? $t("FATURADO") : $t("PENDENTE")
                      }}</span>
                    </template>

                    <template v-slot:[`item.saldo`]="{ item }">
                      <span v-if="item.saldo > 0">
                        {{ item.saldo | formatoMonetario }}
                      </span>
                      <span v-else style="color: red">{{
                        item.saldo | formatoMonetario
                      }}</span>
                    </template>

                    <template v-slot:[`item.situacao`]="{ item }">
                      {{ $t(item.situacao) }}
                    </template>

                    <template v-slot:[`item.statusComum`]="{ item }">
                      {{ $t(item.statusComum) }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      {{ $t(item.status) }}
                    </template>

                    <template v-slot:[`item.salarioBase`]="{ item }">
                      <span>{{ item.salarioBase | formatoMonetario }}</span>
                    </template>

                    <template v-slot:[`item.tipoContratacao`]="{ item }">
                      {{ $t(item.tipoContratacao) }}
                    </template>
                    <template v-slot:[`item.tipoProduto`]="{ item }">
                      {{ $t(item.tipoProduto) }}
                    </template>
                    <template v-slot:[`item.disponivel`]="{ item }">
                      <span>{{ item.disponivel ? $t("sim") : $t("nao") }}</span>
                    </template>
                    <template v-slot:[`item.tipoImobilizado`]="{ item }">
                      {{ $t(item.tipoImobilizado) }}
                    </template>
                    <template v-slot:[`item.statusEstoque`]="{ item }">
                      {{ $t(item.statusEstoque) }}
                    </template>
                    <template v-slot:[`item.tipoTributacao`]="{ item }">
                      {{ $t(item.tipoTributacao) }}
                    </template>

                    <template v-slot:[`item.cpf`]="{ item }">
                      <span>{{ item.cpf | VMask("###.###.###-##") }}</span>
                    </template>

                    <template v-slot:[`item.pessoa.cpf`]="{ item }">
                      <span>{{
                        item.pessoa.cpf | VMask("###.###.###-##")
                      }}</span>
                    </template>

                    <template v-slot:[`item.vencimento`]="{ item }">
                      <span>{{ item.vencimento | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.previsaoCompensacao`]="{ item }">
                      <span>{{
                        item.previsaoCompensacao | dataParaLeitura
                      }}</span>
                    </template>

                    <template v-slot:[`item.dataEfetivacao`]="{ item }">
                      <span>{{ item.dataEfetivacao | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.dataInicio`]="{ item }">
                      <span>{{ item.dataInicio | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.dataTermino`]="{ item }">
                      <span>{{ item.dataTermino | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.dtAlteracao`]="{ item }">
                      <span>{{ item.dtAlteracao | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.dtInclusao`]="{ item }">
                      <span>{{ item.dtInclusao | dataParaLeitura }}</span>
                    </template>
                    <template v-slot:[`item.inicioVigencia`]="{ item }">
                      <span>{{ item.inicioVigencia | dataParaLeitura }}</span>
                    </template>
                    <template v-slot:[`item.finalVigencia`]="{ item }">
                      <span>{{ item.finalVigencia | dataParaLeitura }}</span>
                    </template>
                    <template v-slot:[`item.dataSolicitacao`]="{ item }">
                      <span>{{ item.dataSolicitacao | dataParaLeitura }}</span>
                    </template>
                    <template v-slot:[`item.competencia`]="{ item }">
                      <span>{{
                        item.competencia | competenciaParaLeitura
                      }}</span>
                    </template>
                    <template
                      v-slot:[`item.registroPagamento.dataEfetivacao`]="{
                        item,
                      }"
                    >
                      <span>{{
                        item.registroPagamento.dataEfetivacao | dataParaLeitura
                      }}</span>
                    </template>
                    <template
                      v-slot:[`item.registroRecebimento.dataEfetivacao`]="{
                        item,
                      }"
                    >
                      <span>{{
                        item.registroRecebimento.dataEfetivacao
                          | dataParaLeitura
                      }}</span>
                    </template>
                    <template v-slot:[`item.principal`]="{ item }">
                      <span>{{ item.principal ? $t("sim") : $t("nao") }}</span>
                    </template>
                    <template v-slot:[`item.retencao`]="{ item }">
                      <span>{{ item.retencao ? $t("sim") : $t("nao") }}</span>
                    </template>
                    <template v-slot:[`item.previsao`]="{ item }">
                      <span>{{ item.previsao ? $t("sim") : $t("nao") }}</span>
                    </template>
                    <template v-slot:[`item.escalaIntermitente`]="{ item }">
                      <span>{{
                        item.escalaIntermitente ? $t("sim") : $t("nao")
                      }}</span>
                    </template>
                    <template v-slot:[`item.presente`]="{ item }">
                      <span>{{ item.presente ? $t("sim") : $t("nao") }}</span>
                    </template>

                    <template v-slot:[`item.jornadaTipoApuracao`]="{ item }">
                      <span>{{ item.jornadaTipoApuracao | capitalize }}</span>
                    </template>
                    <template v-slot:[`item.inicio`]="{ item }">
                      <span>{{ item.inicio | dataHoraParaLeitura }}</span>
                    </template>
                    <template v-slot:[`item.inicioPeriodo`]="{ item }">
                      <span>{{ item.inicioPeriodo | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.finalPeriodo`]="{ item }">
                      <span>{{ item.finalPeriodo | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.encerramento`]="{ item }">
                      <span>{{ item.encerramento | dataHoraParaLeitura }}</span>
                    </template>
                    <template v-slot:[`item.encerrado`]="{ item }">
                      <span>{{ $t(item.encerrado ? "sim" : "nao") }}</span>
                    </template>
                    <template v-slot:[`item.publicar`]="{ item }">
                      <span>{{ $t(item.publicar ? "sim" : "nao") }}</span>
                    </template>
                    <template v-slot:[`item.inconsistencia`]="{ item }">
                      <span>{{ $t(item.inconsistencia ? "sim" : "nao") }}</span>
                    </template>

                    <template v-slot:[`item.nRegistro`]="{ item }">
                      <span v-if="item.nRegistro">{{
                        item.nRegistro
                          | VMask(
                            item.nRegistro.length > 11
                              ? $t("maskCNPJ")
                              : $t("maskCPF")
                          )
                      }}</span>
                    </template>

                    <template v-slot:[`item.cliente.nRegistro`]="{ item }">
                      <span v-if="item.cliente.nRegistro">{{
                        item.cliente.nRegistro
                          | VMask(
                            item.cliente.nRegistro.length > 11
                              ? $t("maskCNPJ")
                              : $t("maskCPF")
                          )
                      }}</span>
                    </template>

                    <template v-slot:[`item.data`]="{ item }">
                      {{ item.data | dataHoraParaLeitura }}
                    </template>

                    <template v-slot:[`item.statusPedido`]="{ item }">
                      {{ $t(item.statusPedido) }}
                    </template>

                    <template v-slot:[`item.dataPedido`]="{ item }">
                      <span>{{ item.dataPedido | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.dataRetorno`]="{ item }">
                      <span>{{ item.dataRetorno | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.dataAquisicao`]="{ item }">
                      <span>{{ item.dataAquisicao | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.percentual`]="{ item }">
                      <span>{{ item.percentual | formatoPercentual }}</span>
                    </template>

                    <template v-slot:[`item.tipoApuracao`]="{ item }">
                      {{ $t(item.tipoApuracao) }}
                    </template>

                    <template v-slot:[`item.beneficioConvenio.tipo`]="{ item }">
                      {{ $t(item.beneficioConvenio.tipo) }}
                    </template>

                    <template v-slot:[`item.tipoFeriado`]="{ item }">
                      {{ $t(item.tipoFeriado) }}
                    </template>

                    <template v-slot:[`item.valorUnitario`]="{ item }">
                      <span>{{ item.valorUnitario | formatoMonetario }}</span>
                    </template>

                    <template v-slot:[`item.contaVinculada`]="{ item }">
                      <span>{{
                        item.contaVinculada ? $t("sim") : $t("nao")
                      }}</span>
                    </template>

                    <template v-slot:[`item.deducaoPisCofins`]="{ item }">
                      <span>{{
                        item.deducaoPisCofins ? $t("sim") : $t("nao")
                      }}</span>
                    </template>

                    <template v-slot:[`item.origemAvisoPrevio`]="{ item }">
                      {{ $t(item.origemAvisoPrevio) }}
                    </template>

                    <template v-slot:[`item.beneficioAvisoPrevio`]="{ item }">
                      {{ $t(item.beneficioAvisoPrevio) }}
                    </template>

                    <template v-slot:[`item.tipoServico`]="{ item }">
                      {{ $t(item.tipoServico) }}
                    </template>

                    <template v-slot:[`item.executado`]="{ item }">
                      <span>{{ item.executado ? $t("sim") : $t("nao") }}</span>
                    </template>

                    <template v-slot:[`item.dataAbertura`]="{ item }">
                      <span>{{ item.dataAbertura | dataParaLeitura }}</span>
                    </template>

                    <template v-slot:[`item.tipoAvisoPrevio`]="{ item }">
                      {{ $t(item.tipoAvisoPrevio) }}
                    </template>
                    <template v-slot:[`item.classificacao`]="{ item }">
                      {{ $t(item.classificacao) }}
                    </template>
                    <template v-slot:[`item.statusRoteiro`]="{ item }">
                      {{ $t(item.statusRoteiro) }}
                    </template>
                    <template v-slot:[`item.valido`]="{ item }">
                      <span>{{ item.valido ? $t("sim") : $t("nao") }}</span>
                    </template>

                    <template v-slot:[`item.impressaoComCabecalho`]="{ item }">
                      <span>{{
                        item.impressaoComCabecalho ? $t("sim") : $t("nao")
                      }}</span>
                    </template>
                    <template v-slot:[`item.impressaoSemCabecalho`]="{ item }">
                      <span>{{
                        item.impressaoSemCabecalho ? $t("sim") : $t("nao")
                      }}</span>
                    </template>

                    <template v-slot:[`item.janelaOrigemInicio`]="{ item }">
                      <span>{{
                        item.janelaOrigemInicio | dataParaLeitura
                      }}</span>
                    </template>
                    <template v-slot:[`item.janelaOrigemFinal`]="{ item }">
                      <span>{{
                        item.janelaOrigemFinal | dataParaLeitura
                      }}</span>
                    </template>
                    <template v-slot:[`item.janelaDestinoFinal`]="{ item }">
                      <span>{{
                        item.janelaDestinoFinal | dataParaLeitura
                      }}</span>
                    </template>
                    <template v-slot:[`item.janelaDestinoInicio`]="{ item }">
                      <span>{{
                        item.janelaDestinoInicio | dataParaLeitura
                      }}</span>
                    </template>
                    <template v-slot:[`item.previsaoAtendimento`]="{ item }">
                      {{ item.previsaoAtendimento | horaParaLeitura }}
                    </template>
                    <template v-slot:[`item.inicioAtendimento`]="{ item }">
                      {{ item.inicioAtendimento | horaParaLeitura }}
                    </template>
                    <template v-slot:[`item.finalAtendimento`]="{ item }">
                      {{ item.finalAtendimento | horaParaLeitura }}
                    </template>
                    <template v-slot:[`item.valorDeclarado`]="{ item }">
                      <span>{{ item.valorDeclarado | formatoMonetario }}</span>
                    </template>
                    <template v-slot:[`item.statusAtendimento`]="{ item }">
                      {{ $t(item.statusAtendimento) }}
                    </template>
                    <template v-slot:[`item.tipoEquipamento`]="{ item }">
                      {{ $t(item.tipoEquipamento) }}
                    </template>
                    <template v-slot:[`item.fabricante`]="{ item }">
                      {{ $t(item.fabricante) }}
                    </template>

                    <template
                      v-slot:[`item.faturamentoDocumentoFiscal.dataEmissao`]="{
                        item,
                      }"
                    >
                      <span>{{
                        (item.faturamentoDocumentoFiscal != null
                          ? item.faturamentoDocumentoFiscal.dataEmissao
                          : null) | dataParaLeitura
                      }}</span>
                    </template>
                    <template v-slot:[`item.actionsPedidoMaterial`]="{ item }">
                      <v-icon
                        v-if="item.statusPedido === 'PENDENTE'"
                        small
                        name="separacaoBtn"
                        class="mr-2"
                        :label="$t('Separar')"
                        :title="$t('Separar')"
                        v-can="'ROLE_' + nomeSingular + '_Separar'"
                        @click="confirmaSeparacao(item)"
                        color="#67748e"
                        >mdi-distribute-vertical-top</v-icon
                      >

                      <v-icon
                        small
                        v-if="item.statusPedido === 'SEPARACAO'"
                        name="DisponibilizarBtn"
                        class="mr-2"
                        :label="$t('Disponibilizar')"
                        :title="$t('Disponibilizar')"
                        v-can="'ROLE_' + nomeSingular + '_Disponibilizar'"
                        @click="confirmaDisponibilizacao(item)"
                        color="#67748e"
                        >mdi-archive-arrow-up</v-icon
                      >

                      <v-icon
                        v-if="
                          item.statusPedido === 'PENDENTE' ||
                          item.statusPedido === 'SEPARACAO'
                        "
                        small
                        name="retiradaTerceiroBtn"
                        class="mr-2"
                        :label="$t('Retirada_terceiro')"
                        :title="$t('Retirada_terceiro')"
                        v-can="'ROLE_' + nomeSingular + '_Terceiro'"
                        @click="confirmaRetiradoTerceiro(item)"
                        >mdi-archive-minus-outline</v-icon
                      >

                      <v-icon
                        v-if="item.statusPedido === 'DISPONIBILIZADO'"
                        small
                        name="receberBtn"
                        class="mr-2"
                        :label="$t('Receber')"
                        :title="$t('Receber')"
                        v-can="'ROLE_' + nomeSingular + '_Retirar'"
                        @click="confirmaRecebimento(item)"
                        color="#67748e"
                        >mdi-call-received</v-icon
                      >

                      <v-icon
                        v-if="
                          item.statusPedido === 'RECEBIDO' ||
                          item.statusPedido === 'RETIRADOTERCEIRO'
                        "
                        small
                        name="entregaPendenteBtn"
                        class="mr-2"
                        :label="$t('Entrega_pendente')"
                        :title="$t('Entrega_pendente')"
                        v-can="'ROLE_' + nomeSingular + '_Receber'"
                        @click="confirmaEntrega(item)"
                        color="#67748e"
                        >mdi-dolly</v-icon
                      >

                      <v-icon
                        v-if="
                          item.statusPedido === 'RECEBIDO' ||
                          item.statusPedido === 'ENTREGAPENDENTE'
                        "
                        small
                        name="entregaTotalBtn"
                        class="mr-2"
                        :label="$t('Entrega_total')"
                        :title="$t('Entrega_total')"
                        v-can="'ROLE_' + nomeSingular + '_Receber'"
                        @click="confirmaEntregaTotal(item)"
                        color="#67748e"
                        >mdi-human-dolly</v-icon
                      >

                      <v-icon
                        v-if="
                          item.statusPedido === 'RECEBIDO' ||
                          item.statusPedido === 'ENTREGAPENDENTE' ||
                          item.statusPedido === 'ENTREGAPARCIAL'
                        "
                        small
                        name="entregaParcialBtn"
                        class="mr-2"
                        :label="$t('Entrega_parcial')"
                        :title="$t('Entrega_parcial')"
                        v-can="'ROLE_' + nomeSingular + '_Receber'"
                        @click="recebimentoDetalhadoInf(item)"
                        color="#67748e"
                        >mdi-human-male-board-poll</v-icon
                      >

                      <v-icon
                        small
                        name="printBtn"
                        class="mr-2"
                        :label="$t('Imprimir')"
                        :title="$t('Imprimir')"
                        v-can="'ROLE_' + nomeSingular + '_List'"
                        @click="printSingle(item.id, 'pedidoMaterial')"
                        color="#67748e"
                        >ni-collection</v-icon
                      >
                      <!-- <v-icon
              v-can="'ROLE_' + nomeSingular + '_Delete'"
              small
              name="deleteBtn"
              @click="confirmaDelete(item)"
              >ni-fat-remove</v-icon
            > -->
                    </template>

                    <template v-slot:[`item.actionsPedidoUniforme`]="{ item }">
                      <v-icon
                        v-if="item.statusPedido === 'PENDENTE'"
                        small
                        name="separacaoBtn"
                        :label="$t('Separar')"
                        :title="$t('Separar')"
                        v-can="'ROLE_' + nomeSingular + '_Separar'"
                        @click="confirmaSeparacao(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        >mdi-distribute-vertical-top</v-icon
                      >
                      <v-icon
                        v-if="item.statusPedido === 'SEPARACAO'"
                        small
                        name="DisponibilizarBtn"
                        :label="$t('Disponibilizar')"
                        :title="$t('Disponibilizar')"
                        v-can="'ROLE_' + nomeSingular + '_Disponibilizar'"
                        @click="confirmaDisponibilizacao(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        >mdi-archive-arrow-up</v-icon
                      >
                      <v-icon
                        v-if="item.statusPedido === 'DISPONIBILIZADO'"
                        small
                        name="retirarBtn"
                        :label="$t('Retirar')"
                        :title="$t('Retirar')"
                        v-can="'ROLE_' + nomeSingular + '_Retirar'"
                        @click="confirmaRetirada(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        >mdi-truck-cargo-container</v-icon
                      >
                      <v-icon
                        v-if="item.statusPedido === 'RETIRADO'"
                        small
                        name="receberBtn"
                        :label="$t('Receber')"
                        :title="$t('Receber')"
                        v-can="'ROLE_' + nomeSingular + '_Receber'"
                        @click="confirmaRecebimento(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        >mdi-call-received</v-icon
                      >
                      <v-icon
                        v-if="
                          item.statusPedido !== 'DEVOLUCAOTOTAL' &&
                          item.statusPedido !== 'DEVOLUCAOPARCIAL' &&
                          item.statusPedido !== 'PENDENTE'
                        "
                        small
                        name="DisponibilizarBtn"
                        :label="$t('Troca')"
                        :title="$t('Troca')"
                        v-can="'ROLE_' + nomeSingular + '_Disponibilizar'"
                        @click="confirmaTroca(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        >mdi-find-replace</v-icon
                      >
                      <v-icon
                        v-if="item.statusPedido === 'RECEBIDO'"
                        small
                        name="entregaTotalBtn"
                        :label="$t('Devolucao_total')"
                        :title="$t('Devolucao_total')"
                        v-can="'ROLE_' + nomeSingular + '_Receber'"
                        @click="confirmaEntregaTotal(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        >mdi-human-dolly</v-icon
                      >
                      <v-icon
                        v-if="
                          item.statusPedido === 'RECEBIDO' ||
                          item.statusPedido === 'DEVOLUCAOPARCIAL'
                        "
                        small
                        name="entregaParcialBtn"
                        :label="$t('Devolucao_parcial')"
                        :title="$t('Devolucao_parcial')"
                        v-can="'ROLE_' + nomeSingular + '_Receber'"
                        @click="recebimentoDetalhadoInf(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        >mdi-human-male-board-poll</v-icon
                      >
                      <v-icon
                        small
                        name="printBtn"
                        :label="$t('Imprimir')"
                        :title="$t('Imprimir')"
                        v-can="'ROLE_' + nomeSingular + '_List'"
                        @click="printSingle(item.id, 'pedidoUniforme')"
                        elevation="1"
                        class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                        >ni-collection</v-icon
                      >
                    </template>

                    <template
                      v-slot:[`item.actionsPedidoConsumivel`]="{ item }"
                    >
                      <v-icon
                        v-if="item.statusPedido === 'PENDENTE'"
                        small
                        name="separacaoBtn"
                        class="mr-2"
                        :label="$t('Separar')"
                        :title="$t('Separar')"
                        v-can="'ROLE_' + nomeSingular + '_Separar'"
                        @click="confirmaSeparacao(item)"
                        color="#67748e"
                        >mdi-distribute-vertical-top</v-icon
                      >

                      <v-icon
                        v-if="item.statusPedido === 'SEPARACAO'"
                        small
                        name="DisponibilizarBtn"
                        class="mr-2"
                        :label="$t('Disponibilizar')"
                        :title="$t('Disponibilizar')"
                        v-can="'ROLE_' + nomeSingular + '_Disponibilizar'"
                        @click="confirmaDisponibilizacao(item)"
                        color="#67748e"
                        >mdi-archive-arrow-up</v-icon
                      >

                      <v-icon
                        v-if="
                          item.statusPedido === 'DISPONIBILIZADO' ||
                          item.statusPedido === 'SEPARACAO'
                        "
                        small
                        name="receberBtn"
                        class="mr-2"
                        :label="$t('Receber')"
                        :title="$t('Receber')"
                        v-can="'ROLE_' + nomeSingular + '_Retirar'"
                        @click="confirmaRecebimento(item)"
                        color="#67748e"
                        >mdi-call-received</v-icon
                      >

                      <v-icon
                        small
                        name="printBtn"
                        class="mr-2"
                        :label="$t('Imprimir')"
                        :title="$t('Imprimir')"
                        v-can="'ROLE_' + nomeSingular + '_List'"
                        @click="printSingle(item.id, 'pedidoConsumivel')"
                        color="#67748e"
                        >ni-collection</v-icon
                      >

                      <v-icon
                        small
                        name="printBtn"
                        class="mr-2"
                        :label="$t('Imprimir')"
                        :title="$t('Imprimir')"
                        v-can="'ROLE_' + nomeSingular + '_Value'"
                        @click="
                          printSingle(item.id, 'pedidoConsumivelCompleto')
                        "
                        color="#67748e"
                        >ni-money-coins</v-icon
                      >

                      <v-icon
                        v-if="
                          item.documentoFiscal !== null &&
                          ((item.documentoFiscal.statusDocumento !== 'RPS' &&
                            item.documentoFiscal.statusDocumento !==
                              'EMPROCESSAMENTO' &&
                            item.documentoFiscal.statusDocumento !==
                              'NAOAUTORIZADO') ||
                            item.documentoFiscal.faturamentoPracaFiscal
                              .tipoDocumentoFiscal === 'RECIBO')
                        "
                        small
                        :title="$t('Imprimir')"
                        v-can="'ROLE_FaturamentoDocumentoFiscal_List'"
                        @click="printDoc(item.documentoFiscal)"
                        color="#67748e"
                        class="btn-ls me-0.90 my-1 rounded-sm ma-1"
                        icon
                        elevation="0"
                        :ripple="false"
                        >ni-collection</v-icon
                      >
                      <v-icon
                        v-if="
                          item.documentoFiscal !== null &&
                          item.documentoFiscal.xml !== null
                        "
                        small
                        :title="$t('XML')"
                        v-can="'ROLE_FaturamentoDocumentoFiscal_List'"
                        @click="printXml(item.documentoFiscal)"
                        color="#67748e"
                        class="btn-ls me-0.90 my-1 rounded-sm ma-1"
                        icon
                        elevation="0"
                        :ripple="false"
                        >mdi-xml</v-icon
                      >
                      <v-icon
                        v-if="
                          item.documentoFiscal !== null &&
                          (item.documentoFiscal.statusDocumento === 'RPS' ||
                            item.documentoFiscal.statusDocumento ===
                              'NAOAUTORIZADO') &&
                          item.documentoFiscal.faturamentoPracaFiscal
                            .tipoDocumentoFiscal !== 'RECIBO'
                        "
                        small
                        id="gerarDoc"
                        :title="$t('Gerar_documento')"
                        v-can="'ROLE_FaturamentoDocumentoFiscal_List'"
                        @click="integrar(item.documentoFiscal)"
                        color="#67748e"
                        class="btn-ls me-0.90 my-1 rounded-sm ma-1"
                        icon
                        elevation="0"
                        :ripple="false"
                        >ni-spaceship</v-icon
                      >
                      <v-icon
                        small
                        v-if="
                          item.documentoFiscal !== null &&
                          (item.documentoFiscal.statusDocumento ===
                            'EMPROCESSAMENTO' ||
                            item.documentoFiscal.statusDocumento ===
                              'NAOAUTORIZADO')
                        "
                        :title="$t('Consultar_documento')"
                        v-can="'ROLE_FaturamentoDocumentoFiscal_List'"
                        @click="consultar(item.documentoFiscal)"
                        color="#67748e"
                        class="btn-ls me-0.90 my-1 rounded-sm ma-1"
                        icon
                        elevation="0"
                        :ripple="false"
                        >ni-paper-diploma</v-icon
                      >
                      <!-- <v-icon
              v-can="'ROLE_' + nomeSingular + '_Delete'"
              small
              name="deleteBtn"
              @click="confirmaDelete(item)"
              >ni-fat-remove</v-icon
            > -->
                    </template>

                    <template
                      v-slot:[`item.actionsMovimentacaoMaterial`]="{ item }"
                    >
                      <v-icon
                        v-if="item.statusPedido === 'PENDENTE'"
                        small
                        name="DisponibilizarBtn"
                        class="mr-2"
                        :label="$t('Disponibilizar')"
                        :title="$t('Disponibilizar')"
                        v-can="'ROLE_' + nomeSingular + '_Disponibilizar'"
                        @click="confirmaDisponibilizacao(item)"
                        color="#67748e"
                        >mdi-archive-arrow-up</v-icon
                      >

                      <v-icon
                        v-if="
                          item.statusPedido === 'PENDENTE' ||
                          item.statusPedido === 'DISPONIBILIZADO'
                        "
                        small
                        name="retirarBtn"
                        class="mr-2"
                        :label="$t('Receber')"
                        :title="$t('Receber')"
                        v-can="'ROLE_' + nomeSingular + '_Retirar'"
                        @click="confirmaRecebimento(item)"
                        color="#67748e"
                        >mdi-call-received</v-icon
                      >

                      <v-icon
                        v-if="item.statusPedido === 'RETIRADO'"
                        small
                        name="ReceberBtn"
                        class="mr-2"
                        :label="$t('Receber')"
                        :title="$t('Receber')"
                        v-can="'ROLE_' + nomeSingular + '_Receber'"
                        @click="confirmaEntregaTotal(item)"
                        color="#67748e"
                        >mdi-human-dolly</v-icon
                      >

                      <v-icon
                        small
                        name="printBtn"
                        class="mr-2"
                        :label="$t('Imprimir')"
                        :title="$t('Imprimir')"
                        v-can="'ROLE_' + nomeSingular + '_List'"
                        @click="printSingle(item.id, 'movimentacaoImobilizado')"
                        color="#67748e"
                        >ni-collection</v-icon
                      >
                      <!-- <v-icon
              v-can="'ROLE_' + nomeSingular + '_Delete'"
              small
              name="deleteBtn"
              @click="confirmaDelete(item)"
              >ni-fat-remove</v-icon
            > -->
                    </template>
                    <template v-slot:[`item.validacao01`]="{ item }">
                      <span>{{
                        item.validacao01 ? $t("sim") : $t("nao")
                      }}</span>
                    </template>
                    <template v-slot:[`item.validacao02`]="{ item }">
                      <span>{{
                        item.validacao02 ? $t("sim") : $t("nao")
                      }}</span>
                    </template>
                    <template
                      v-slot:[`item.actionsRegistrosFinanceiroValidacao`]="{
                        item,
                      }"
                    >
                      <v-icon
                        v-if="item.validacao01 !== null && !item.validacao01"
                        small
                        name="validacao01"
                        :label="$t('Validar')"
                        :title="$t('Validar')"
                        v-can="'ROLE_' + nomeSingular + '_Validation01'"
                        @click="confirmaValidacaoFinanceira01(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-1 my-1 rounded-sm"
                        color="#67748e"
                        >mdi-clipboard-account</v-icon
                      >
                      <v-icon
                        v-if="
                          item.validacao01 !== null &&
                          item.validacao02 !== null &&
                          item.validacao01 &&
                          !item.validacao02
                        "
                        small
                        name="validacao02"
                        :label="$t('Validar')"
                        :title="$t('Validar')"
                        v-can="'ROLE_' + nomeSingular + '_Validation02'"
                        @click="confirmaValidacaoFinanceira02(item)"
                        elevation="1"
                        class="ma-1 btn-ls me-1 my-1 rounded-sm"
                        color="#67748e"
                        >mdi-clipboard-check</v-icon
                      >
                    </template>
                  </v-data-table>
                </v-card-text>

                <v-card-actions class="card-padding">
                  <v-row>
                    <v-col cols="6" lg="6" class="d-flex align-center">
                      <span class="text-body me-3"
                        >{{ $t("Itens_por_pagina") }}:</span
                      >
                      <v-col cols="3" sm="3">
                        <v-select
                          v-model="pageSize"
                          :items="pageSizes"
                          @change="handlePageSizeChange"
                          :disabled="this.isSearching"
                          :item-value="pageSize"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>
                    </v-col>
                    <v-col cols="6" class="ml-auto d-flex justify-end">
                      <v-pagination
                        prev-icon="fa fa-angle-left"
                        next-icon="fa fa-angle-right"
                        class="pagination"
                        color="#516a78ee"
                        v-model="page"
                        :length="totalPages"
                        @input="handlePageChange"
                        :disabled="isSearching"
                        circle
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </div> </template></v-col
      ></v-row>
    </v-container>
  </div>
</template>

<script>
import CampoHorario from "@/components/base/proprios/CampoHorario.vue";
import CamposTipoNregistro from "@/components/base/proprios/CamposTipoNregistro";
import MapaLocalPonto from "@/components/base/proprios/MapaLocalPonto";
import ShowImage from "@/components/base/proprios/ShowImage";
import ExpandedRoteiro from "@/components/operacoes/roteiros/ExpandedRoteiro";
import { componentPaths } from "@/router/componentPaths";

import ComumDataService from "@/services/ComumDataService";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "ListagemBase",
  components: {
    MapaLocalPonto,
    ShowImage,
    ExpandedRoteiro,
    CamposTipoNregistro,
    CampoHorario,
  },
  props: {
    nomePlural: { type: String, required: true },
    nomeSingular: { type: String, required: true },
    headers: { type: Array, required: true },

    //Props para alterações específicas ao caso
    stringCadastro: { type: String, required: false, default: "novo_cadastro" },
    caixaLegenda: { type: Boolean, required: false, default: true },
    expandableProp: { type: Boolean, required: false, default: false },
    singleExpandProp: { type: Boolean, required: false, default: false },
    buscaSimplificada: { type: Boolean, required: false, default: true },
    pesquisaVencimento: { type: Boolean, required: false, default: false },
    pesquisaPeriodo: { type: Boolean, required: false, default: false },
    pesquisaDetalhe: { type: Boolean, required: false, default: false },
    pesquisaContaFinanceira: { type: Boolean, required: false, default: false },
    pesquisaContaVinculada: { type: Boolean, required: false, default: false },
    pesquisasituacaoMovimentacaoContaVinculada: {
      type: Boolean,
      required: false,
      default: false,
    },
    pesquisaFornecedor: { type: Boolean, required: false, default: false },
    pesquisaCliente: { type: Boolean, required: false, default: false },
    pesquisaValor: { type: Boolean, required: false, default: false },
    pesquisaFaturado: { type: Boolean, required: false, default: false },
    pesquisaStatusFinanceiro: {
      type: Boolean,
      required: false,
      default: false,
    },
    pesquisaFuncionario: { type: Boolean, required: false, default: false },
    pesquisaBeneficioConvenio: {
      type: Boolean,
      required: false,
      default: false,
    },
    pesquisaStatusPedidoUniforme: {
      type: Boolean,
      required: false,
      default: false,
    },

    pesquisaNome: { type: Boolean, required: false, default: false },
    pesquisaMatricula: { type: Boolean, required: false, default: false },
    pesquisaPostoTrabalho: { type: Boolean, required: false, default: false },
    pesquisaData: { type: Boolean, required: false, default: false },
    pesquisaPessoa: { type: Boolean, required: false, default: false },
    pesquisaCompetencia: { type: Boolean, required: false, default: false },
    pesquisaDataAtualTv: { type: Boolean, required: false, default: false },
    pesquisaDataSeguinteTv: { type: Boolean, required: false, default: false },
    pesquisaContaBancaria: { type: Boolean, required: false, default: false },
    atualizarDadosAoEditar: {
      type: Boolean,
      required: false,
      default: false,
    },
    opcoesLegenda: {
      type: Array,
      required: false,
      default() {
        return [
          { text: "Ativo", color: "green" },
          { text: "Inativo", color: "red" },
        ];
      },
    },
    editar: { type: Boolean, required: false, default: true },
    botaoCadastro: { type: Boolean, required: false, default: true },
    botaoTransferenciaEmpresaFuncionario: {
      type: Boolean,
      required: false,
      default: false,
    },
    botaoImport: {
      type: Boolean,
      required: false,
      default: false,
    },
    botaoImportContaContabil: {
      type: Boolean,
      required: false,
      default: false,
    },
    botaoGerarRoteiroModelo: { type: Boolean, required: false, default: true },
  },

  data() {
    return {
      dialogInsert: false,
      dialogEdit: false,

      dialogSuccesEdit: false,
      dialogSuccesInsert: false,

      currentItem: null,
      currentItem: {},
      recursoAtual: { id: 0 },
      editComponent: null,
      insertComponent: null,
      lowerCaseComponentPaths: [],

      mask: currencyMask,
      //Recursos
      recursos: [],
      errors: [],
      //Modais
      confirmar: false,
      confirmarReplicar: false,
      confirmarDisponibilizacaoDocumento: false,
      objetoAtual: {},
      deletado: false,
      recebimentoDetalhado: false,
      formChangeProdutoPedido: false,
      quantidadeDevolvida: 0,
      quantidade: 0,
      quantidadePendente: 0,
      previsao: false,
      confirmarQuantidadeDesabilitarBotao: false,
      confirmarSeparacao: false,
      confirmarDisponibilizacao: false,
      confirmarTroca: false,
      confirmarRetiradoTerceiro: false,
      confirmarEntrega: false,
      confirmarRecebimento: false,
      confirmarEntregaTotal: false,
      confirmarRetirada: false,
      statusFinanceiro: "",
      valorTitulosQuitados: null,
      valorTitulosPendentes: null,
      valorTitulosVencidos: null,
      keyModal: 0,
      quantidadePedidosPendentes: 0,
      pedidosPendentes: [],
      modalPedidosPendentes: false,
      pedidoAtual: {
        origem: { descricao: "", cidade: { nome: "", estado: { sigla: "" } } },
        destino: { descricao: "", cidade: { nome: "", estado: { sigla: "" } } },
      },
      roteirosDisponiveis: [],
      modalRoteirizarPedido: false,
      novoTrajeto: {
        previsaoAtendimentoOrigem: "",
        previsaoAtendimentoDestino: "",
        roteiro: { id: 0 },
        roteiroId: 0,
      },
      modalGerarRoteiroModelo: false,

      modalEventosTv: false,
      modalEventoTv: false,
      eventosTv: [],
      eventosTvOpcoes: [],
      eventoTvAtual: {
        evento: {},
        ocorrencia: {},
        data: "",
        detalhes: "",
      },

      modalOcorrenciasTv: false,
      modalNovoOcorrenciaTv: false,
      ocorrenciasTv: [],
      ocorrenciasTvOpcoes: [],
      ocorrenciaTvAtual: {},

      roteiroModelo: { id: 0 },
      roteiroModelos: [],
      headerPedidos: [
        // { text: this.$t("Data"), value: "data", sortable: "false" },
        // { text: this.$t("Status"), value: "statusPedido", sortable: "false" },
        {
          text: this.$t("Classificacao"),
          value: "classificacao",
          sortable: "false",
        },
        {
          text: this.$t("Cliente"),
          value: "cliente.fantasia",
          sortable: "false",
        },
        {
          text: this.$t("Origem"),
          value: "origem.descricao",
          sortable: "false",
        },
        {
          text: this.$t("Cidade"),
          value: "origem.cidade.nome",
          sortable: "false",
        },
        {
          text: this.$t("Uf"),
          value: "origem.cidade.estado.sigla",
          sortable: "false",
        },
        {
          text: this.$t("Bairro"),
          value: "origem.bairro",
          sortable: "false",
        },
        {
          text: this.$t("janelaOrigemInicio"),
          value: "janelaOrigemInicio",
          sortable: "false",
        },
        {
          text: this.$t("janelaOrigemFinal"),
          value: "janelaOrigemFinal",
          sortable: "false",
        },

        {
          text: this.$t("Destino"),
          value: "destino.descricao",
          sortable: "false",
        },
        {
          text: this.$t("Cidade"),
          value: "destino.cidade.nome",
          sortable: "false",
        },
        {
          text: this.$t("Uf"),
          value: "destino.cidade.estado.sigla",
          sortable: "false",
        },
        {
          text: this.$t("Bairro"),
          value: "destino.bairro",
          sortable: "false",
        },
        {
          text: this.$t("janelaDestinoInicio"),
          value: "janelaDestinoInicio",
          sortable: "false",
        },
        {
          text: this.$t("janelaDestinoFinal"),
          value: "janelaDestinoFinal",
          sortable: "false",
        },
        {
          text: this.$t("Valor_total"),
          value: "valorTotal",
          sortable: "false",
        },

        { text: this.$t("Acoes"), value: "actionsPedidos", sortable: "false" },
      ],
      quantidadeTitulosQuitados: 0,
      quantidadeTitulosPendentes: 0,
      quantidadeTitulosVencidos: 0,
      quantidadeFiltros: 0,
      loading: false,
      modalTransferenciaEmpresa: false,
      objTransferenciaEmpresa: {
        dataTransferencia: "",
        matriculaAtual: "",
        matriculaNova: "",
        nregistroDestino: "",
      },

      modalConfirmaValidacaoFinanceira01: false,
      modalConfirmaValidacaoFinanceira02: false,

      produto: {},

      menuFuncionarioPontoDetalhes: false,

      //tabelas
      search: "",
      isSearching: false,

      page: 1,
      totalPages: 0,
      pageSize: 8,
      pageSizes: [8, 16, 24],
      orderBy: null,
      inicio: "",
      fim: "",
      filteredItems: [],
      pedidoItems: {},
      competencia: "",
      vencimentoInicio: "",
      vencimentoFim: "",
      inicioPeriodo: "",
      encerramento: "",
      detalhe: "",
      matricula: "",
      nome: "",
      data: "",
      valorFinanceiro: "",
      fornecedores: [],
      itemsFornecedor: [],
      contasFinanceiras: [],
      contasVinculada: [],
      contaVinculada: {},
      contaBancaria: "",
      situacaoMovimentacaoContaVinculada: "",
      itemsContaFinanceira: [],
      clientes: [],
      itemsCliente: [],
      funcionarios: [],
      itemsFuncionario: [],
      pessoas: [],
      itemsPessoa: [],
      beneficioConvenios: [],
      itemsBeneficioConvenio: [],
      postoTrabalhos: [],
      itemsPostoTrabalho: [],
      quantidade: 0,
      headersItemPedido: [
        {
          text: this.$t("Descricao"),
          value: "produto.descricao",
          align: "start",
          sortable: "false",
        },
        {
          text: this.$t("Quantidade"),
          value: "quantidade",
          sortable: "true",
        },
      ],
      statusReader: "",
      line: "",
      contentFile: null,
      direction: null,
      directions: [
        { text: this.$t("Crescente"), value: "ASC" },
        { text: this.$t("Decrescente"), value: "DESC" },
      ],
      modalImportArquivo: false,
      modalConfirmaAprovacao: false,
      modalConfirmaGeracaoDocumento: false,
      modalConfirmaExecucaoServico: false,
      modalGuias: false,
      headerGuias: [
        {
          text: this.$t("numeroDocumento"),
          value: "numeroDocumento",
          sortable: "false",
        },
        {
          text: this.$t("serieDocumento"),
          value: "serieDocumento",
          sortable: "false",
        },
        {
          text: this.$t("Cliente"),
          value: "cliente.razaoSocial",
          sortable: "false",
        },
        {
          text: this.$t("statusDocumento"),
          value: "statusDocumento",
          sortable: "false",
        },
        {
          text: this.$t("valorDeclarado"),
          value: "valorDeclarado",
        },

        { text: this.$t("Acoes"), value: "actionsGuias", sortable: "false" },
      ],
      headerEventosTv: [
        {
          text: this.$t("Data"),
          value: "data",
          sortable: "false",
        },
        {
          text: this.$t("Ocorrencia"),
          value: "logisticaTipoOcorrencia.descricao",
          sortable: "false",
        },
        {
          text: this.$t("Evento"),
          value: "logisticaTipoEvento.descricao",
          sortable: "false",
        },
        {
          text: this.$t("Responsavel"),
          value: "responsavel.nome",
          sortable: "false",
        },
        {
          text: this.$t("ExportacaoRealizada"),
          value: "exportacaoRealizada",
          sortable: "false",
        },

        {
          text: this.$t("Data_de_inclusao"),
          value: "dtInclusao",
          sortable: "false",
        },
        { text: this.$t("Acoes"), value: "edit", sortable: "false" },
      ],

      headerOcorrenciasTv: [
        {
          text: this.$t("Tipo"),
          value: "logisticaTipoOcorrencia.descricao",
          sortable: "false",
        },
        {
          text: this.$t("Responsavel"),
          value: "responsavel.nome",
          sortable: "false",
        },
        {
          text: this.$t("Data_de_inclusao"),
          value: "dtInclusao",
          sortable: "false",
        },
      ],
      ruleQuantidadePendente: (val) => {
        console.log("ruleQuantidadePendente", val);
        if (val > this.quantidadePendente) {
          this.confirmarQuantidadeDesabilitarBotao = true;
          return this.$t("Quantidade_maior_que_pendencia");
        } else if (val <= 0) {
          this.confirmarQuantidadeDesabilitarBotao = true;
          return this.$t("Quantidade_deve_ser_maior_que_zero");
        } else {
          this.confirmarQuantidadeDesabilitarBotao = false;
          return true;
        }
      },
      tipoImportacaoExterna: "",
      dataAprovacao: null,
      idDialog: 0,
    };
  },

  watch: {
    search: function () {
      this.handleSearchChange();
    },
  },

  mounted() {
    this.sugestaoVencimento();
    this.sugestaoPeriodo();
    if (this.pesquisaContaFinanceira) {
      this.getContasFinanceiras();
    }
    if (this.pesquisaContaVinculada) {
      this.getContasVinculada();
    }
    if (this.pesquisaFornecedor) {
      this.getFornecedores();
    }
    if (
      this.pesquisaCliente ||
      this.nomeSingular === "ModeloDocumentacaoAcessoria"
    ) {
      this.getClientes();
    }
    if (this.pesquisaFuncionario) {
      this.getFuncionarios();
    }
    if (this.pesquisaBeneficioConvenio) {
      this.getBeneficioConvenios();
    }
    if (
      this.pesquisaPostoTrabalho ||
      this.nomeSingular === "ModeloDocumentacaoAcessoria"
    ) {
      this.getPostoTrabalhos();
    }
    if (this.pesquisaPessoa) {
      this.getPessoas();
    }
    if (this.pesquisaDataAtualTv) {
      this.dataAtualTv();
      // this.isSearching = true;
    }
    if (this.pesquisaDataSeguinteTv) {
      this.dataSeguinteTv();
      // this.isSearching = true;
    }

    if (this.pesquisaDataAtualTv) {
      this.handleSearchChange();
    } else {
      this.retrieveData();
    }
  },

  computed: {
    recursoUpper: function () {
      return this.nomePlural.charAt(0).toUpperCase() + this.nomePlural.slice(1);
    },

    headersFiltrados: function () {
      return this.headers.map((item) => {
        item.sortable = "false";
        return item;
      });
    },
  },

  methods: {
    possuiDocumentacaoAcessoria() {
      try {
        let param = this.$store.state.inquilino.modulosContratacao.find(
          (item) => {
            return item.tipo == "DOCUMENTACAOACESSORIAAUTOMATICA";
          }
        );
        if (param != undefined && param.id != undefined) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    possuiLogisticaTransporte() {
      try {
        let param = this.$store.state.inquilino.modulosContratacao.find(
          (item) => {
            return item.tipo == "GLT";
          }
        );
        if (param != undefined && param.id != undefined) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    async loadEditComponent() {
      try {
        const targetName = `editar.${this.nomeSingular.toLowerCase()}`;
        console.log("targetName", targetName);
        if (componentPaths[targetName]) {
          const componentModule = await componentPaths[targetName]();
          const component = componentModule.default || componentModule;
          if (!component || (!component.template && !component.render)) {
            console.error(`Componente inválido para a rota: ${targetName}`);
            this.editComponent = {
              template: "<div>Componente não encontrado ou inválido</div>",
            };
          } else {
            this.editComponent = component;
          }
        } else {
          console.error(
            `Caminho do componente não encontrado para a rota: ${targetName}`
          );
          this.editComponent = {
            template: "<div>Componente não encontrado</div>",
          };
        }
      } catch (error) {
        console.error(
          `Erro ao importar componente para a rota ${targetName}:`,
          error
        );
        this.editComponent = {
          template: "<div>Erro ao carregar o componente</div>",
        };
      }
    },
    async loadInsertComponent() {
      try {
        const targetName = `cadastro.${this.nomeSingular}`;
        console.log("targetName", targetName);
        if (componentPaths[targetName]) {
          const componentModule = await componentPaths[targetName]();
          const component = componentModule.default || componentModule;
          if (!component || (!component.template && !component.render)) {
            console.error(`Componente inválido para a rota: ${targetName}`);
            this.insertComponent = {
              template: "<div>Componente não encontrado ou inválido</div>",
            };
          } else {
            this.insertComponent = component;
          }
        } else {
          console.error(
            `Caminho do componente não encontrado para a rota: ${targetName}`
          );
          this.insertComponent = {
            template: "<div>Componente não encontrado</div>",
          };
        }
      } catch (error) {
        console.error(
          `Erro ao importar componente para a rota ${targetName}:`,
          error
        );
        this.insertComponent = {
          template: "<div>Erro ao carregar o componente</div>",
        };
      }
    },
    openEditarModal(item) {
      this.recursoAtual = item;
      this.dialogEdit = true;
      this.loadEditComponent(); // Carregar o componente dinamicamente quando abrir o modal
    },

    openInsertModal() {
      this.dialogInsert = true;
      this.loadInsertComponent(); // Carregar o componente dinamicamente
    },

    openInsertModalAdmissaoResumida() {
      this.nomeSingular = "FuncionarioResumed";
      this.openInsertModal();
      this.loading = false;
    },
    closeInsertModal() {
      this.dialogInsert = false;
      this.insertComponent = null;
    },

    closeEditarModal() {
      console.log("closeEditarModal");
      this.dialogEdit = false;
      this.editComponent = null;
    },
    onSubmitSuccessEdit() {
      console.log("onSubmitSuccessEdit acessou");
      console.log("onSubmitSuccessEdit", this.atualizarDadosAoEditar);
      if (this.atualizarDadosAoEditar) {
        this.isSearching = false;
        this.handleSearchChange();
      }

      this.closeEditarModal();
      this.dialogSuccesEdit = false;
      this.dialogSuccesEdit = true;
    },

    onSubmitSuccessInsert(repeat, formData) {
      console.log("ListagemBase, repeat", repeat);
      console.log("ListagemBase, formData", formData);
      this.isSearching = false;
      if (this.isSearching) {
        this.isSearching = false;
        this.handleSearchChange();
      } else {
        this.retrieveData();
      }
      console.log("repeat", repeat);
      if (repeat) {
        this.$swal.fire(
          this.$t("cadastrado_sucesso"),
          this.$t("pode_realizar_alteracoes_novo_cadastro"),
          "success"
        );
        // Caso o checkbox "Repetir" esteja marcado, reabrimos o modal
        this.repeatFormData = formData;
        this.openInsertModal();
      } else {
        this.closeInsertModal();
        this.dialogSuccesInsert = false;
        this.dialogSuccesInsert = true;
      }
    },
    onSubmitError() {
      console.log("onSubmitError");
      // this.closeEditarModal();
    },
    onSubmitErrorInsert() {
      console.log("onSubmitErrorInsert");
      // this.closeEditarModal();
    },
    // openEditarModal(item) {
    //   this.recursoAtual = item; // Set the current resource to be edited
    //   this.dialogEdit = true;
    // },

    filtrar() {
      this.handleSearchChange();
    },
    handleFilterChange(event) {
      console.log("current-items", event);
    },

    editRecurso(id) {
      console.log(id);
      this.$router.push({
        name: "editar." + this.nomeSingular.toLowerCase(),
        params: { id: id },
      });
    },

    exportCSV() {
      this.loading = true;

      if (this.statusFinanceiro === "") {
        this.statusFinanceiro = null;
      }
      if (this.situacaoMovimentacaoContaVinculada === "") {
        this.situacaoMovimentacaoContaVinculada = null;
      }
      this.$parent.DataService.export({
        responseType: "blob",
        vencimentoInicio: this.vencimentoInicio,
        vencimentoFim: this.vencimentoFim,
        detalhes: this.detalhe,
        contas: this.itemsContaFinanceira,
        fornecedores: this.itemsFornecedor,
        clientes: this.itemsCliente,
        valor: this.valorFinanceiro,
        status: this.statusFinanceiro,
        funcionarios: this.itemsFuncionario,
        convenios: this.itemsBeneficioConvenio,
        postoTrabalhos: this.itemsPostoTrabalho,
        matricula: this.matricula,
        nome: this.nome,
        data: this.data,
        pessoas: this.itemsPessoa,
        faturado: this.faturado,
        inicioPeriodo: this.inicioPeriodo,
        encerramentoPeriodo: this.encerramentoPeriodo,
        competencia: this.competencia,
        situacaoMovimentacaoContaVinculada:
          this.situacaoMovimentacaoContaVinculada,
        contaVinculada: this.contaVinculada,
      })
        .then((res) => {
          this.loading = false;
          this.download(res.data, "Cajupe_Export.csv", "text/csv");
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Documento_gerado_com_sucesso"),
            "success"
          );
        })
        .catch((err) => {
          this.loading = false;
          console.log("err", err);
        });
    },

    exportPDF() {
      this.loading = true;

      if (this.statusFinanceiro === "") {
        this.statusFinanceiro = null;
      }
      if (this.situacaoMovimentacaoContaVinculada === "") {
        this.situacaoMovimentacaoContaVinculada = null;
      }
      this.$parent.DataService.exportPdf(
        {
          vencimentoInicio: this.vencimentoInicio,
          vencimentoFim: this.vencimentoFim,
          detalhes: this.detalhe,
          contas: this.itemsContaFinanceira,
          fornecedores: this.itemsFornecedor,
          clientes: this.itemsCliente,
          valor: this.valorFinanceiro,
          status: this.statusFinanceiro,
          funcionarios: this.itemsFuncionario,
          convenios: this.itemsBeneficioConvenio,
          postoTrabalhos: this.itemsPostoTrabalho,
          matricula: this.matricula,
          nome: this.nome,
          data: this.data,
          pessoas: this.itemsPessoa,
          faturado: this.faturado,
          inicioPeriodo: this.inicioPeriodo,
          encerramentoPeriodo: this.encerramentoPeriodo,
          competencia: this.competencia,
          situacaoMovimentacaoContaVinculada:
            this.situacaoMovimentacaoContaVinculada,
          contaVinculada: this.contaVinculada,
        },
        { responseType: "blob" }
      )
        .then((res) => {
          this.loading = false;
          console.log("res.data", res.data);
          this.download(res.data, "Document.pdf", "application/pdf");
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Documento_gerado_com_sucesso"),
            "success"
          );
        })
        .catch((err) => {
          this.loading = false;
          console.log("err", err);
        });
    },

    confirmaTransferenciaEmpresa() {
      console.log("confirmaTransferenciaEmpresa");
      this.objTransferenciaEmpresa.matriculaAtual = "";
      this.objTransferenciaEmpresa.matriculaNova = "";
      this.objTransferenciaEmpresa.dataTransferencia = "";
      this.objTransferenciaEmpresa.nregistroDestino = "";
      this.modalTransferenciaEmpresa = true;
    },
    replicateRecurso() {
      this.$parent.DataService.replicate({
        item: this.objetoAtual,
        quantidade: this.quantidade,
        previsao: this.previsao,
      })
        .then(() => {
          this.retrieveData();
          this.quantidade = 0;
          this.confirmarReplicar = false;
          this.previsao = false;
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Replicado_com_sucesso"),
            "success"
          );
        })
        .catch((error) => {
          this.confirmarReplicar = false;
          this.quantidade = 0;
          this.previsao = false;
          console.log(error);
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Tente_novamente_mais_tarde"),
            "error"
          );
        });
    },

    deleteRecurso() {
      this.$parent.DataService.delete(this.objetoAtual.id)
        .then(() => {
          this.confirmar = false;
          this.deletado = true;
          if (this.isSearching) {
            this.handleSearchChange();
          } else {
            this.retrieveData();
          }
        })
        .catch((error) => {
          this.confirmar = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          this.errors = error.response.data.errors;
        });
    },

    disponibilizarDocumentoFuncionarios() {
      this.loading = true;
      this.$parent.DataService.disponibilizarDocumentoFuncionarios(
        this.objetoAtual.id,
        {
          clientes: this.itemsCliente,
          postoTrabalhos: this.itemsPostoTrabalho,
          matricula: this.matricula,
          nome: this.nome,
        }
      )
        .then(() => {
          this.confirmarDisponibilizacaoDocumento = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Documento_gerado_com_sucesso"),
            "success"
          );
        })
        .catch((error) => {
          this.confirmar = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          this.confirmarDisponibilizacaoDocumento = false;
          this.errors = error.response.data.errors;
          this.loading = false;
        });
    },

    isStatusComum: function (item) {
      if (item.statusPedido) {
        if (item.statusPedido === "CANCELADO") {
          return "INATIVO";
        }
        return item.statusPedido;
      } else if (item.situacao) {
        if (
          new Date(item.vencimento) < new Date() &&
          item.situacao == "PENDENTE"
        ) {
          return "VENCIDO";
        }

        return item.situacao;
      } else if (item.statusAtendimento && item.roteiro) {
        const roteiroNumero = parseInt(item.roteiro);
        if (roteiroNumero % 2 !== 0) {
          if (item.statusAtendimento === "CONCLUIDO") {
            return "TRAJETOIMPARCONCLUIDO";
          } else {
            return "TRAJETOIMPAR";
          }
        } else {
          if (item.statusAtendimento === "CONCLUIDO") {
            return "TRAJETOPARCONCLUIDO";
          } else {
            return "TRAJETOPAR";
          }
        }
      } else if (item.statusAtendimento) {
        return item.statusAtendimento;
      } else if (item.statusComum) {
        return item.statusComum;
      } else if (item.status) {
        if (item.status == "DESLIGADO") {
          return "INATIVO";
        } else {
          return item.status;
        }
      } else {
        return "ATIVO";
      }
    },

    //Modais de confirmação
    confirmaDelete(recurso) {
      this.objetoAtual = recurso;
      this.confirmar = true;
    },

    confirmaReplicate(recurso) {
      this.objetoAtual = recurso;
      this.confirmarReplicar = true;
    },

    confirmaDisponibilizacaoDocumento(recurso) {
      this.objetoAtual = recurso;
      this.confirmarDisponibilizacaoDocumento = true;
    },

    confirmaAprovacao(recurso) {
      this.idDialog += 1;
      this.objetoAtual = recurso;
      this.modalConfirmaAprovacao = true;
    },

    confirmaGeracaoDocumento(recurso) {
      this.idDialog += 1;
      this.objetoAtual = recurso;
      this.modalConfirmaGeracaoDocumento = true;
    },

    confirmaExecucaoServico(recurso) {
      this.idDialog += 1;
      this.objetoAtual = recurso;
      console.log("confirmaExecucaoServico", this.objetoAtual);
      this.modalConfirmaExecucaoServico = true;
    },

    listGuias(recurso) {
      this.idDialog += 1;
      this.objetoAtual = recurso;
      this.modalGuias = true;
    },

    listEventosTv(recurso) {
      this.idDialog += 1;
      this.objetoAtual = recurso;
      this.eventosTv = [];
      this.loading = true;

      ComumDataService.get("roteiroTrajetos/eventos/" + recurso.id, {})
        .then((res) => {
          this.eventosTv = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });

      this.modalEventosTv = true;
    },

    confirmaNovoEventoTv() {
      this.idDialog += 1;
      this.eventosTvOpcoes = [];
      this.eventoTvAtual = {
        evento: {},
        ocorrencia: {},
        data: "",
        detalhes: "",
      };
      this.loading = true;
      ComumDataService.get("logisticaTipoEventos", {})
        .then((res) => {
          this.eventosTvOpcoes = res.data;
        })
        .catch((err) => {});
      ComumDataService.get("logisticaTipoOcorrencias", {})
        .then((res) => {
          this.ocorrenciasTvOpcoes = res.data;
        })
        .catch((err) => {});

      this.loading = false;

      this.modalEventoTv = true;
    },

    openEditarModalEventoTv(item) {
      this.eventoTvAtual = item;
      this.eventoTvAtual.ocorrencia = item.logisticaTipoOcorrencia;
      this.eventoTvAtual.evento = item.logisticaTipoEvento;
      this.loading = true;
      ComumDataService.get("logisticaTipoEventos", {})
        .then((res) => {
          this.eventosTvOpcoes = res.data;
        })
        .catch((err) => {});
      ComumDataService.get("logisticaTipoOcorrencias", {})
        .then((res) => {
          this.ocorrenciasTvOpcoes = res.data;
        })
        .catch((err) => {});

      console.log("openEditarModalEventoTv", this.eventoTvAtual);
      this.loading = false;
      this.modalEventoTv = true;
    },

    registroNovoEventoTv() {
      this.idDialog += 1;

      this.loading = true;

      if (this.eventoTvAtual.id === undefined) {
        ComumDataService.post("roteiroTrajetos/evento", {
          idTrajeto: this.objetoAtual.id,
          idOcorrencia: this.eventoTvAtual.ocorrencia.id,
          idEvento: this.eventoTvAtual.evento.id,
          data: this.eventoTvAtual.data,
          detalhes: this.eventoTvAtual.detalhes,
        })
          .then((res) => {
            this.loading = false;
            this.modalEventoTv = false;

            this.listEventosTv(this.objetoAtual);
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        ComumDataService.put("roteiroTrajetos/evento", {
          id: this.eventoTvAtual.id,
          idTrajeto: this.objetoAtual.id,
          idOcorrencia: this.eventoTvAtual.ocorrencia.id,
          idEvento: this.eventoTvAtual.evento.id,
          data: this.eventoTvAtual.data,
          detalhes: this.eventoTvAtual.detalhes,
        })
          .then((res) => {
            this.loading = false;
            this.modalEventoTv = false;

            this.listEventosTv(this.objetoAtual);
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },

    printGtv(item) {
      this.$parent.DataService.get("guiaTransportes/print/" + item.id, {
        responseType: "blob",
      })
        .then((res) => {
          console.log("res", res, res.data);
          this.download(
            res.data,
            "GTV_" + item.numeroDocumento + ".pdf",
            "application/pdf"
          );
          this.loading = false;
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Documento_gerado_com_sucesso"),
            "success"
          );
        })
        .catch((err) => {
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Falha_ao_exportar"),
            "error"
          );
        });
    },

    confirmaSeparacao(recurso) {
      this.objetoAtual = recurso;
      this.confirmarSeparacao = true;
    },

    confirmaDisponibilizacao(recurso) {
      this.objetoAtual = recurso;
      this.confirmarDisponibilizacao = true;
    },

    confirmaTroca(recurso) {
      this.objetoAtual = recurso;
      this.confirmarTroca = true;
    },

    confirmaRetiradoTerceiro(recurso) {
      this.objetoAtual = recurso;
      this.confirmarRetiradoTerceiro = true;
    },

    confirmaEntrega(recurso) {
      this.objetoAtual = recurso;
      this.confirmarEntrega = true;
    },

    confirmaRecebimento(recurso) {
      this.objetoAtual = recurso;
      this.confirmarRecebimento = true;
    },

    confirmaRetirada(recurso) {
      this.objetoAtual = recurso;
      this.confirmarRetirada = true;
    },

    confirmaEntregaTotal(recurso) {
      this.objetoAtual = recurso;
      this.confirmarEntregaTotal = true;
    },

    confirmaValidacaoFinanceira01(recurso) {
      this.objetoAtual = recurso;
      this.modalConfirmaValidacaoFinanceira01 = true;
    },
    confirmaValidacaoFinanceira02(recurso) {
      this.objetoAtual = recurso;
      this.modalConfirmaValidacaoFinanceira02 = true;
    },

    recebimentoDetalhadoInf(recurso) {
      console.log("recurso", recurso);
      if (
        recurso.statusPedido === "RECEBIDO" ||
        recurso.statusPedido === "ENTREGAPENDENTE" ||
        recurso.statusPedido === "DEVOLUCAOPARCIAL" ||
        recurso.statusPedido === "ENTREGAPARCIAL"
      ) {
        this.objetoAtual = recurso;
        // this.pedidoItems = recurso.items;
        // console.log("pedidoItems", recurso);
        this.recebimentoDetalhado = true;
      } else {
        this.$swal.fire(
          this.$t("Erro"),
          this.$t("Nao_foi_possivel_alterar_o_status"),
          "error"
        );
      }
    },

    transferenciaEmpresa() {
      console.log("inf", this.objTransferenciaEmpresa);
      // this.confirmarSeparacao = true;
      this.objTransferenciaEmpresa.nregistroDestino =
        this.objTransferenciaEmpresa.nregistroDestino.replace(/\$/gi, "");

      this.objTransferenciaEmpresa.nregistroDestino =
        this.objTransferenciaEmpresa.nregistroDestino.replace(/\,/gi, "");
      this.objTransferenciaEmpresa.nregistroDestino =
        this.objTransferenciaEmpresa.nregistroDestino.replace(/\./gi, "");
      this.objTransferenciaEmpresa.nregistroDestino =
        this.objTransferenciaEmpresa.nregistroDestino.replace(/\-/gi, "");
      this.objTransferenciaEmpresa.nregistroDestino =
        this.objTransferenciaEmpresa.nregistroDestino.replace(/\//gi, "");
      this.objTransferenciaEmpresa.nregistroDestino =
        this.objTransferenciaEmpresa.nregistroDestino.replace(/([A-z])/, "");

      this.$parent.DataService.transferCompany(this.objTransferenciaEmpresa)
        .then((response) => {
          this.modalTransferenciaEmpresa = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Transferencia_realizada_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          console.log("erro", e.errors);
          this.modalTransferenciaEmpresa = false;
          this.loading = false;
          this.$swal.fire(this.$t("Erro"), this.$t(e), "error");
        });
    },

    //Requisições
    retrieveData() {
      this.loading = true;
      this.inicio = this.vencimentoInicio;
      this.fim = this.vencimentoFim;

      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.orderBy,
        this.direction,
        this.inicio,
        this.fim,
        this.data
      );
      this.$parent.DataService.getPage(params)
        .then((response) => {
          this.recursos = response.data.content;
          this.totalPages = response.data.totalPages;
          this.pageSize = response.data.size;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //Paginação
    getRequestParams(page, pageSize, orderBy, direction, inicio, fim, date) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["linesPerPage"] = pageSize;
      }

      if (orderBy) {
        params["orderBy"] = orderBy;
      }

      if (direction) {
        params["direction"] = direction;
      }
      if (inicio) {
        params["inicio"] = inicio;
      }
      if (fim) {
        params["fim"] = fim;
      }
      if (date) {
        params["date"] = date;
      }

      return params;
    },

    handleSearchChange() {
      console.log("handleSearchChange", this.recursos);
      //Tratamento temporario, remover
      if (this.buscaSimplificada) {
        if (this.search.length > 0 && !this.isSearching) {
          console.log("entrou 01");
          this.isSearching = true;
          this.loading = true;
          this.$parent.DataService.getAll()
            .then((response) => {
              this.recursos = response.data;
              this.loading = false;
            })
            .catch((e) => {
              console.log("handleSearchChange, 1Catch", e);
              this.loading = false;
            });
        }

        if (this.search.length < 1 && this.isSearching) {
          console.log("entrou 02");
          this.isSearching = false;
          this.retrieveData();
        }
      } else {
        if (
          this.detalhe.length === 0 &&
          this.itemsContaFinanceira.length === 0 &&
          this.itemsFornecedor.length === 0 &&
          this.itemsCliente.length === 0 &&
          this.valorFinanceiro.length === 0 &&
          this.statusFinanceiro === null &&
          this.itemsFuncionario.length === 0 &&
          this.itemsBeneficioConvenio.length === 0 &&
          this.itemsPostoTrabalho.length === 0 &&
          this.matricula.length === 0 &&
          this.nome.length === 0 &&
          this.data.length === 0 &&
          !this.pesquisaVencimento &&
          !this.pesquisaPeriodo &&
          this.faturado === null &&
          this.itemsPessoa.length === 0 &&
          this.situacaoMovimentacaoContaVinculada.length === 0
        ) {
          console.log("entrou 03");
          this.isSearching = false;
          this.retrieveData();
        } else {
          console.log("Busca Detalhada");
          this.isSearching = true;
          this.loading = true;
          if (this.valorFinanceiro === "0.00") {
            this.valorFinanceiro = null;
          }
          if (this.statusFinanceiro === "") {
            this.statusFinanceiro = null;
          }
          if (this.situacaoMovimentacaoContaVinculada === "") {
            this.situacaoMovimentacaoContaVinculada = null;
          }
          this.$parent.DataService.getFilter({
            vencimentoInicio: this.vencimentoInicio,
            vencimentoFim: this.vencimentoFim,
            detalhes: this.detalhe,
            contas: this.itemsContaFinanceira,
            fornecedores: this.itemsFornecedor,
            clientes: this.itemsCliente,
            valor: this.valorFinanceiro,
            status: this.statusFinanceiro,
            funcionarios: this.itemsFuncionario,
            convenios: this.itemsBeneficioConvenio,
            postoTrabalhos: this.itemsPostoTrabalho,
            matricula: this.matricula,
            nome: this.nome,
            data: this.data,
            pessoas: this.itemsPessoa,
            faturado: this.faturado,
            inicioPeriodo: this.inicioPeriodo,
            encerramentoPeriodo: this.encerramentoPeriodo,
            competencia: this.competencia,
            situacaoMovimentacaoContaVinculada:
              this.situacaoMovimentacaoContaVinculada,
            contaVinculada: this.contaVinculada,
            contaBancaria: this.contaBancaria,
          })
            .then((response) => {
              this.loading = false;
              if (
                this.nomeSingular === "TituloPagar" ||
                this.nomeSingular === "TituloReceber"
              ) {
                this.recursos = response.data.titulos;
                this.valorTitulosQuitados = response.data.valorQuitados;
                this.valorTitulosPendentes = response.data.valorAvencer;
                this.valorTitulosVencidos = response.data.valorVencidos;

                this.quantidadeTitulosPendentes =
                  response.data.quantidadeAvencer;
                this.quantidadeTitulosQuitados =
                  response.data.quantidadeQuitados;
                this.quantidadeTitulosVencidos =
                  response.data.quantidadeVencidos;
              } else if (this.nomeSingular === "RoteiroTrajeto") {
                this.recursos = response.data.itens;
                this.quantidadePedidosPendentes =
                  response.data.quantidadePedidoPendente;
              } else {
                this.recursos = response.data;
              }
            })
            .catch((error) => {
              console.log("handleSearchChange, 2Catch", error);
              this.loading = false;
            });
        }
      }
    },

    handlePageChange(value) {
      this.page = value;
      this.retrieveData();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.retrieveData();
    },

    handleOrderByChange(value) {
      this.orderBy = value;
      this.page = 1;
      this.retrieveData();
    },

    separacao() {
      this.loading = true;
      this.$parent.DataService.separacao(this.objetoAtual)
        .then((response) => {
          this.confirmarSeparacao = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Pedido_separado_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.confirmarSeparacao = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_separar_o_pedido"),
            "error"
          );
        });
    },

    disponibilizar() {
      this.loading = true;
      this.$parent.DataService.disponibilizado(this.objetoAtual)
        .then((response) => {
          this.confirmarDisponibilizacao = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Pedido_disponibilizado_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.confirmarDisponibilizacao = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_disponibilizar_o_pedido"),
            "error"
          );
        });
    },

    trocar() {
      this.loading = true;
      this.$parent.DataService.trocar(this.objetoAtual)
        .then((response) => {
          this.confirmarTroca = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Pedido_disponivel_para_troca"),
            "success"
          );
        })
        .catch((e) => {
          this.confirmarTroca = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_disponibilizar_o_pedido"),
            "error"
          );
        });
    },

    retiradaTerceiro() {
      this.loading = true;
      this.$parent.DataService.retiradoTerceiro(this.objetoAtual)
        .then((response) => {
          this.confirmarRetiradoTerceiro = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Pedido_retirado_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.confirmarRetiradoTerceiro = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_retirar_o_pedido"),
            "error"
          );
        });
    },

    retirar() {
      this.loading = true;
      this.$parent.DataService.retirado(this.objetoAtual)
        .then((response) => {
          this.confirmarRetirada = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Pedido_retirado_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.confirmarRetirada = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_retirar_o_pedido"),
            "error"
          );
        });
    },

    receber() {
      this.loading = true;
      this.$parent.DataService.recebido(this.objetoAtual)
        .then((response) => {
          this.confirmarRecebimento = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Pedido_retirado_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.confirmarRecebimento = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_retirar_o_pedido"),
            "error"
          );
        });
    },

    entregaPendente() {
      this.loading = true;
      this.$parent.DataService.entregaPendente(this.objetoAtual)
        .then((response) => {
          this.confirmarEntrega = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Entrega_pendente_conferencia_registrada_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.confirmarEntrega = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_registrar_a_entrega_pendente"),
            "error"
          );
        });
    },

    entregaTotal() {
      this.loading = true;
      this.$parent.DataService.entregaTotal(this.objetoAtual)
        .then((response) => {
          this.confirmarEntregaTotal = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Entrega_total_registrada_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.confirmarEntregaTotal = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_registrar_a_entrega_total"),
            "error"
          );
        });
    },

    validacaoFinanceira01() {
      this.loading = true;
      this.$parent.DataService.validacaoFinanceira01(this.objetoAtual)
        .then((response) => {
          this.modalConfirmaValidacaoFinanceira01 = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Validacao_registrada_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.modalConfirmaValidacaoFinanceira01 = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_registrar_a_validacao"),
            "error"
          );
        });
    },

    validacaoFinanceira02() {
      this.loading = true;
      this.$parent.DataService.validacaoFinanceira02(this.objetoAtual)
        .then((response) => {
          this.modalConfirmaValidacaoFinanceira02 = false;
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Validacao_registrada_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.modalConfirmaValidacaoFinanceira02 = false;
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_registrar_a_validacao"),
            "error"
          );
        });
    },

    aprovar() {
      this.loading = true;
      this.$parent.DataService.aprovar({
        id: this.objetoAtual.id,
        data: this.dataAprovacao,
      })
        .then((response) => {
          this.dataAprovacao = null;
          this.modalConfirmaAprovacao = false;

          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Validacao_registrada_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.dataAprovacao = null;
          this.modalConfirmaAprovacao = false;

          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_registrar_a_validacao"),
            "error"
          );
        });
    },

    gerarDocumento() {
      this.loading = true;
      this.$parent.DataService.gerarDocumento({
        id: this.objetoAtual.id,
        data: this.dataAprovacao,
      })
        .then((response) => {
          this.modalConfirmaGeracaoDocumento = false;

          this.loading = false;
          this.handleSearchChange();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Documento_gerado_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.modalConfirmaGeracaoDocumento = false;

          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Falha_ao_gerar_documento"),
            "error"
          );
        });
    },

    registroExecucaoServico() {
      this.loading = true;
      this.$parent.DataService.registrarExecucao(this.objetoAtual.id)
        .then((response) => {
          this.modalConfirmaExecucaoServico = false;

          this.loading = false;

          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Registrado_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.modalConfirmaExecucaoServico = false;

          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Falha_ao_gerar_documento"),
            "error"
          );
        });
    },

    entregaParcial(item) {
      console.log("item", item);
      this.recebimentoDetalhado = false;
      this.loading = true;
      this.$parent.DataService.entregaParcial(item)
        .then((response) => {
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Entrega_parcial_registrada_com_sucesso"),
            "success"
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_registrar_a_entrega_parcial"),
            "error"
          );
        });
    },

    printSingle(id, type) {
      this.loading = true;
      if (type === "pedidoMaterial") {
        this.$parent.DataService.get("pedidoMaterial/romaneio/" + id, {
          responseType: "blob",
        })
          .then((res) => {
            console.log("printSingle", res.data);
            this.loading = false;
            this.download(res.data, "document.pdf", "application/pdf");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((error) => {
            this.loading = false;
            this.$swal.fire(
              this.$t("Erro"),
              this.$t("Falha_ao_exportar"),
              "error"
            );
          });
      } else if (type === "movimentacaoImobilizado") {
        this.$parent.DataService.get(
          "imobilizadoMovimentacoes/romaneio/" + id,
          {
            responseType: "blob",
          }
        )
          .then((res) => {
            console.log("printSingle", res.data);
            this.loading = false;
            this.download(res.data, "document.pdf", "application/pdf");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((error) => {
            this.loading = false;
            this.$swal.fire(
              this.$t("Erro"),
              this.$t("Falha_ao_exportar"),
              "error"
            );
          });
      } else if (type === "pedidoUniforme") {
        this.$parent.DataService.get("pedidoUniforme/romaneio/" + id, {
          responseType: "blob",
        })
          .then((res) => {
            console.log("printSingle", res.data);
            this.loading = false;
            this.download(res.data, "document.pdf", "application/pdf");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((error) => {
            this.loading = false;
            this.$swal.fire(
              this.$t("Erro"),
              this.$t("Falha_ao_exportar"),
              "error"
            );
          });
      } else if (type === "pedidoConsumivel") {
        this.$parent.DataService.get("pedidoConsumivel/romaneio/" + id, {
          responseType: "blob",
        })
          .then((res) => {
            console.log("printSingle", res.data);
            this.loading = false;
            this.download(res.data, "document.pdf", "application/pdf");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((error) => {
            this.loading = false;
            this.$swal.fire(
              this.$t("Erro"),
              this.$t("Falha_ao_exportar"),
              "error"
            );
          });
      } else if (type === "pedidoConsumivelCompleto") {
        this.$parent.DataService.get("pedidoConsumivel/romaneio/value/" + id, {
          responseType: "blob",
        })
          .then((res) => {
            console.log("printSingle", res.data);
            this.loading = false;
            this.download(res.data, "document.pdf", "application/pdf");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((error) => {
            this.loading = false;
            this.$swal.fire(
              this.$t("Erro"),
              this.$t("Falha_ao_exportar"),
              "error"
            );
          });
      } else {
        console.log("Entrou no else");
        this.$parent.DataService.print(id, {
          responseType: "blob",
        })
          .then((res) => {
            this.loading = false;
            this.download(res.data, "document.pdf", "application/pdf");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((error) => {
            this.loading = false;
            this.$swal.fire(
              this.$t("Erro"),
              this.$t("Falha_ao_exportar"),
              "error"
            );
          });
      }
    },

    download(content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    },

    handleDirectionChange(value) {
      (this.direction = value), this.retrieveData();
    },
    handleClickPedido(event) {
      this.produto = event.produto;
      console.log("handleClickPedido", event);
      this.quantidadePendente =
        (event.quantidadeEntregue === null ? 0 : event.quantidadeEntregue) -
        (event.quantidadeDevolvida === null ? 0 : event.quantidadeDevolvida);
      console.log(
        "handleClickPedido quantidadePendente",
        this.quantidadePendente
      );
      this.formChangeProdutoPedido = true;
    },

    changeProdutoPedido() {
      var produtoExistente = this.objetoAtual.items.find(
        (produto) => produto.produto.id == this.produto.id
      );

      if (produtoExistente) {
        this.quantidade = parseInt(this.quantidade);
        // + parseInt(produtoExistente.quantidadeDevolvida);
        this.objetoAtual.items = this.objetoAtual.items.filter((produto) => {
          return produto.produto.id !== produtoExistente.produto.id;
        });
      }
      this.objetoAtual.items.push({
        produto: this.produto,
        quantidade: this.quantidade,
      });
      this.produto = {};
      this.quantidade = 0;
      this.formChangeProdutoPedido = false;
    },
    limpaFormProduto() {
      this.formChangeProdutoPedido = false;
      // this.quantidadeSolicitada = 0;
    },

    sugestaoVencimento() {
      var now = new Date();
      this.vencimentoInicio = this.utils_dateObj_to_ymd(
        new Date(now.getFullYear(), now.getMonth(), 1)
      );
      this.vencimentoFim = this.utils_dateObj_to_ymd(
        new Date(now.getFullYear(), now.getMonth() + 1, 0)
      );
    },

    dataAtualTv() {
      var nowTv = new Date();
      const currentHour = nowTv.getHours();

      // Se a hora atual for igual ou maior que 20, adiciona um dia
      if (currentHour > 19) {
        nowTv.setDate(nowTv.getDate() + 1);
      }

      this.data = this.utils_dateObj_to_ymd(nowTv);
      this.inicioPeriodo = this.utils_dateObj_to_ymd(nowTv);
    },

    dataSeguinteTv() {
      var nowTv = new Date();
      const currentHour = nowTv.getHours();

      // Se a hora atual for igual ou maior que 20, adiciona um dia
      if (currentHour < 17) {
        nowTv.setDate(nowTv.getDate());
      } else if (currentHour >= 20) {
        nowTv.setDate(nowTv.getDate() + 2);
      } else {
        nowTv.setDate(nowTv.getDate() + 1);
      }

      this.data = this.utils_dateObj_to_ymd(nowTv);
    },

    sugestaoPeriodo(periodoAnterior) {
      var now = new Date();
      if (periodoAnterior === null) {
        periodoAnterior = false;
      }

      if (periodoAnterior) {
        this.inicioPeriodo = this.utils_dateObj_to_ymd(
          new Date(now.getFullYear(), now.getMonth() - 1, 1)
        );
        this.encerramentoPeriodo = this.utils_dateObj_to_ymd(
          new Date(now.getFullYear(), now.getMonth(), 0)
        );
      } else {
        this.inicioPeriodo = this.utils_dateObj_to_ymd(
          new Date(now.getFullYear(), now.getMonth(), 1)
        );
        this.encerramentoPeriodo = this.utils_dateObj_to_ymd(
          new Date(now.getFullYear(), now.getMonth() + 1, 0)
        );
      }
    },

    utils_dateObj_to_ymd(payload = null) {
      if (payload) {
        var today = new Date(payload);
      } else {
        var today = new Date();
      }
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");

      console.log(date);
      return date;
    },
    handleDataInicioVencimentoChange(value) {
      this.vencimentoInicio = value;
      //this.handleSearchChange();
    },
    handleDataFinalVencimentoChange(value) {
      this.vencimentoFim = value;
      // this.handleSearchChange();
    },

    handleCompetenciaChange(value) {
      this.competencia = value;
      //this.handleSearchChange();
    },

    handleDataInicioPeriodoChange(value) {
      this.inicioPeriodo = value;
      // this.handleSearchChange();
    },
    handleDataEncerramentoPeriodoChange(value) {
      this.encerramentoPeriodo = value;
      //this.handleSearchChange();
    },

    handleDataChange(value) {
      this.data = value;
      // this.handleSearchChange();
    },

    getContasFinanceiras() {
      ComumDataService.get("/contaFinanceiras/conta").then((res) => {
        this.contasFinanceiras = res.data;
      });
    },

    getContasVinculada() {
      ComumDataService.get("/contaBancarias/coorporate/kind/1").then((res) => {
        this.contasVinculada = res.data;
      });
    },

    getFornecedores() {
      ComumDataService.get("/fornecedores").then((res) => {
        this.fornecedores = res.data;
      });
    },

    getClientes() {
      ComumDataService.get("/clientes").then((res) => {
        this.clientes = res.data;
      });
    },

    getFuncionarios() {
      ComumDataService.get("/funcionarios").then((res) => {
        this.funcionarios = res.data;
      });
    },

    getPessoas() {
      ComumDataService.get("/pessoas").then((res) => {
        this.pessoas = res.data;
      });
    },

    getBeneficioConvenios() {
      ComumDataService.get("/beneficioConvenio").then((res) => {
        this.beneficioConvenios = res.data;
      });
    },

    getPostoTrabalhos() {
      ComumDataService.get("/postosTrabalho").then((res) => {
        this.postoTrabalhos = res.data;
      });
    },

    readFile() {
      return new Promise((resolve, reject) => {
        this.file = this.$refs.doc.files[0];
        const reader = new FileReader();
        if (
          this.file.name.includes(".csv") ||
          this.file.name.includes(".txt")
        ) {
          reader.onload = (res) => {
            this.modalSelecionaArquivo = false;
            resolve(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.file);
        } else {
          reader.onerror = reject;
          reader.onload = (res) => {
            this.modalSelecionaArquivo = false;
            resolve(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.file);
        }
      });
    },

    openModalImportArquivo() {
      this.contentFile = null;
      this.modalImportArquivo = true;
      this.tipoImportacaoExterna = "CSV";
    },

    openModalImportContaContabil() {
      this.contentFile = null;
      this.modalImportArquivo = true;
      this.tipoImportacaoExterna = "ContaContabil";
    },

    async importExternal() {
      this.loading = true;
      this.contentFile = await this.readFile();
      console.log("this.file", this.file);

      if (this.nomeSingular === "Funcionario") {
        //Verificar se tem exportacao dexion
        var parametrizacaoDexionExistente =
          this.$store.state.inquilino.parametrizacoes.find(
            (item) => item.tipo == "INTEGRACAOFPDEXION"
          );

        // if (parametrizacaoDexionExistente) {
        //   this.tipoImportacaoExterna = "Dexion";
        // }
      }

      //Importação padrão CSV
      if (this.file.name.includes(".csv")) {
        //Lendo arquivo
        console.log("this.contentFile", this.contentFile);
        //Split
        const lines = this.contentFile.split("\r\n");
        lines.pop();

        //Convertendo para objeto Json
        var jsonObj = [];
        var headers = lines[0].split(";");
        for (var i = 1; i < lines.length; i++) {
          var data = lines[i].split(";");
          var obj = {};
          for (var j = 0; j < data.length; j++) {
            //Tratamentos especificos para formatações

            //Inclui no objeto apenas quando existir valor
            if (data[j].trim().length > 0) {
              //Data
              if (
                data[j].trim().indexOf("/") === 2 &&
                data[j].trim().length === 10
              ) {
                obj[headers[j].trim()] = this.formatedDate(data[j].trim());
              } else if (headers[j].trim().indexOf("valor") !== -1) {
                obj[headers[j].trim()] = data[j]
                  .trim()
                  .replace(".", "")
                  .replace(",", ".");
              } else {
                obj[headers[j].trim()] = data[j].trim();
              }
            }
          }
          jsonObj.push(obj);
        }

        // const output = lines.slice(1).map((line) => {
        //   const fields = line.split(";");
        //   return Object.fromEntries(header.map((h, i) => [h, fields[i]]));
        // });
        console.log("jsonObj", jsonObj);

        if (this.tipoImportacaoExterna === "ContaContabil") {
          this.$parent.DataService.importContaContabil(jsonObj)
            .then(() => {
              this.retrieveData();
              jsonObj = [];
              this.loading = false;
              this.modalImportArquivo = false;
              this.$swal.fire(
                this.$t("Sucesso"),
                this.$t("Importacao_realizada_sucesso"),
                "success"
              );
            })
            .catch((error) => {
              //Tratando objeto de retorno
              jsonObj = [];
              this.loading = false;
              console.log("error", error.response.data.errors);
              var linesErr = error.response.data.errors[0].message.split(",");
              var objTratErr = {};
              var listTratErr = [];
              var lineNumber = 0;
              for (var x = 0; x < linesErr.length; x++) {
                objTratErr = {};
                objTratErr.message = linesErr[x]
                  .substring(linesErr[x].indexOf(":") + 2)
                  .trim();
                lineNumber =
                  parseInt(
                    linesErr[x].substring(
                      linesErr[x].indexOf("[") + 1,
                      linesErr[x].indexOf("]")
                    )
                  ) + 2;
                objTratErr.fieldName =
                  "Linha: " +
                  lineNumber.toString() +
                  " - " +
                  linesErr[x].substring(
                    linesErr[x].indexOf("]") + 2,
                    linesErr[x].indexOf(":")
                  );
                objTratErr.line = lineNumber;
                listTratErr.push(objTratErr);
                //console.log("objTratErr", objTratErr);
              }
              listTratErr = listTratErr.sort(this.compareArrayError);

              this.modalImportArquivo = false;
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              //console.log("error.response", error.response);
              this.errors = listTratErr;
            });
        } else {
          this.$parent.DataService.insertSimplifiedList(jsonObj)
            .then(() => {
              this.retrieveData();
              jsonObj = [];
              this.loading = false;
              this.modalImportArquivo = false;
              this.$swal.fire(
                this.$t("Sucesso"),
                this.$t("Importacao_realizada_sucesso"),
                "success"
              );
            })
            .catch((error) => {
              //Tratando objeto de retorno
              jsonObj = [];
              this.loading = false;
              console.log("error", error.response.data.errors);
              var linesErr = error.response.data.errors[0].message.split(",");
              var objTratErr = {};
              var listTratErr = [];
              var lineNumber = 0;
              for (var x = 0; x < linesErr.length; x++) {
                objTratErr = {};
                objTratErr.message = linesErr[x]
                  .substring(linesErr[x].indexOf(":") + 2)
                  .trim();
                lineNumber =
                  parseInt(
                    linesErr[x].substring(
                      linesErr[x].indexOf("[") + 1,
                      linesErr[x].indexOf("]")
                    )
                  ) + 2;
                objTratErr.fieldName =
                  "Linha: " +
                  lineNumber.toString() +
                  " - " +
                  linesErr[x].substring(
                    linesErr[x].indexOf("]") + 2,
                    linesErr[x].indexOf(":")
                  );
                objTratErr.line = lineNumber;
                listTratErr.push(objTratErr);
                //console.log("objTratErr", objTratErr);
              }
              listTratErr = listTratErr.sort(this.compareArrayError);

              this.modalImportArquivo = false;
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              //console.log("error.response", error.response);
              this.errors = listTratErr;
            });
        }
      } else if (
        this.file.name.includes(".txt") &&
        parametrizacaoDexionExistente
      ) {
        const objetos = [];
        let objetoAtual = {};
        console.log("Import Dexion - init");

        // Divide o documento em linhas e remove as linhas em branco
        const linhas = this.contentFile
          .split("\n")
          .filter((linha) => linha.trim() !== "");

        // Itera pelas linhas do documento
        for (const linha of linhas) {
          const campos = linha.split("|");

          // Preenche o objeto com os campos
          objetoAtual = {
            tipo: campos[1],
            matricula: campos[2],
            nome: campos[3],
            cpf: campos[4],
            nis: campos[5],
            sexo: campos[6],
            nascimento: campos[7],
            nomeMae: campos[8],
            nomePai: campos[9],
            email: campos[10],
            enderecoLogradouro: campos[11],
            enderecoComplemento: campos[12],
            enderecoNumero: campos[13],
            enderecoCep: campos[14],
            enderecoBairro: campos[15],
            enderecoCidade: campos[16],
            enderecoUf: campos[17],
            admissao: campos[18],
            experienciaTermino: campos[19],
            experienciaProrrogacao: campos[20],
            demissao: campos[21],
            afastamentoSequencial: campos[22],
            afastamentoInicio: campos[23],
            afastamentoFim: campos[24],
            afastamentoMotivo: campos[25],
            afastamentoCid: campos[26],
          };

          objetos.push(objetoAtual);
        }

        console.log("objetos", objetos);

        this.$parent.DataService.importDexion(objetos)
          .then(() => {
            this.retrieveData();
            this.loading = false;
            this.modalImportArquivo = false;
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Importacao_realizada_sucesso"),
              "success"
            );
          })
          .catch((error) => {
            this.loading = false;
            this.modalImportArquivo = false;
            console.log("error", error);
            this.$swal.fire(
              this.$t("Erro"),
              this.$t("Arquivo_invalido"),
              "error"
            );
          });
        console.log("Import Dexion - end");
      } else {
        this.loading = false;
        this.modalImportArquivo = false;
        this.$swal.fire(this.$t("Erro"), this.$t("Arquivo_invalido"), "error");
      }
    },

    formatedDate: function (dataValue) {
      var data = dataValue?.toString() || "";
      if (data.toString().length == 10) {
        var dia = data.split("/")[0];
        var mes = data.split("/")[1];
        var ano = data.split("/")[2];

        let string = ano + "-" + mes + "-" + dia;
        return string;
      } else {
        return data;
      }
    },

    compareArrayError: function (a, b) {
      if (a.line < b.line) return -1;
      if (a.line > b.line) return 1;
      return 0;
    },

    printXml(item) {
      window.open(item.xml);
    },

    integrar(objeto) {
      this.loading = true;
      ComumDataService.put(
        "/faturamentoDocumentoFiscais/integration/" + objeto.id
      )
        .then((res) => {
          this.retrieveData();
          this.loading = false;
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Documento_enviado_realize_consulte_a_seguir"),
            "success"
          );
        })
        .catch((err) => {
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Falha_ao_enviar_documento"),
            "error"
          );
        });
      this.confirmarEnvio = false;
    },

    consultar(item) {
      this.loading = true;
      ComumDataService.put("/faturamentoDocumentoFiscais/consult/" + item.id)
        .then((res) => {
          this.retrieveData();
          this.loading = false;
          console.log("Consulta documento", res.data);
          if (res.data.statusDocumento === "NAOAUTORIZADO") {
            const movimentacoes = res.data.movimentacoes;
            const ultimoDetalhe =
              movimentacoes[movimentacoes.length - 1].detalhes;
            console.log("Consulta documento", ultimoDetalhe);
            this.$swal.fire(
              this.$t(res.data.statusDocumento),
              this.$t(ultimoDetalhe),
              "error"
            );
          } else {
            this.$swal.fire(
              this.$t(res.data.statusDocumento),
              this.$t("Documento_consultado_com_sucesso"),
              "success"
            );
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Falha_ao_consultar_documento_tente_novamente"),
            "error"
          );
        });
    },

    printDoc(item) {
      this.loading = true;
      if (item.faturamentoPracaFiscal.tipoDocumentoFiscal === "RECIBO") {
        ComumDataService.get("faturamentoDocumentoFiscais/recibo/" + item.id, {
          responseType: "blob",
        })
          .then((res) => {
            console.log("res", res, res.data);
            this.retrieveData();
            this.download(res.data, "document.pdf", "application/pdf");
            this.loading = false;
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((err) => {
            this.loading = false;
            this.$swal.fire(
              this.$t("Erro"),
              this.$t("Falha_ao_exportar"),
              "error"
            );
          });
      } else {
        window.open(item.danfe);
      }

      this.loading = false;
    },

    showPedidosPendentes() {
      this.loading = true;
      this.keyModal += 1;
      ComumDataService.get("/pedidosTransporte/pending/" + this.data)
        .then((res) => {
          this.pedidosPendentes = res.data;

          this.modalPedidosPendentes = true;
          this.loading = false;
        })
        .catch((err) => {
          this.pedidosPendentes = [];
          this.loading = false;
        });
    },

    showModalRoteirizarPedido(item) {
      this.loading = true;
      this.keyModal += 1;
      this.pedidoAtual = item;
      console.log("this.pedidoAtual", this.pedidoAtual);
      ComumDataService.get("/roteiros/date/" + this.data)
        .then((res) => {
          this.roteirosDisponiveis = res.data;
          console.log("this.roteirosDisponiveis", this.roteirosDisponiveis);
          this.modalRoteirizarPedido = true;
          this.loading = false;
        })
        .catch((err) => {
          this.roteirosDisponiveis = [];
          this.loading = false;
        });
    },
    roteirizarPedido() {
      this.loading = true;
      ComumDataService.post("/roteiroTrajetos", {
        roteiroId: this.novoTrajeto.roteiro.id,
        pedidoId: this.pedidoAtual.id,
        previsaoAtendimentoOrigem: this.novoTrajeto.previsaoAtendimentoOrigem,
        previsaoAtendimentoDestino: this.novoTrajeto.previsaoAtendimentoDestino,
      })
        .then((res) => {
          this.loading = false;
          this.modalPedidosPendentes = false;
          this.modalRoteirizarPedido = false;
          this.handleSearchChange();
        })
        .catch((err) => {
          this.loading = false;
          this.modalPedidosPendentes = false;
        });
    },

    confirmaGerarRoteirosModelo() {
      this.loading = true;
      this.roteiroModelo = { id: 0 };
      ComumDataService.get("/roteiroModelos", {})
        .then((res) => {
          this.loading = false;
          this.roteiroModelo = { id: 0 };
          this.roteiroModelos = res.data;
          this.modalGerarRoteiroModelo = true;
        })
        .catch((err) => {
          this.roteiroModelo = { id: 0 };
          this.loading = false;
          this.modalGerarRoteiroModelo = false;
        });
    },

    gerarRoteirosModelo() {
      console.log("this.roteiroModelo.id", this.roteiroModelo.id);
      console.log("this.data", this.data);

      this.loading = true;

      // Guarde os valores atuais antes de definir this.roteiroModelo como null
      const roteiroModeloId = this.roteiroModelo ? this.roteiroModelo.id : null;
      const dataRoteiro = this.data;

      // Verifique se roteiroModeloId não é nulo antes de continuar
      if (roteiroModeloId === null) {
        console.error("Erro: roteiroModeloId é nulo");
        this.loading = false;
        return;
      }

      // Defina this.roteiroModelo como null após guardar seus valores
      this.roteiroModelo = null;

      ComumDataService.put("/roteiroModelos/route", {
        roteiroModeloId: roteiroModeloId,
        dataRoteiro: dataRoteiro,
      })
        .then(() => {
          this.loading = false;
          this.modalGerarRoteiroModelo = false;
          this.handleSearchChange();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          this.loading = false;
          this.modalGerarRoteiroModelo = false;
        });
    },
  },
};
</script>

<style>
.doubt-btn {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

#app .CANCELADO {
  color: red;
}

.PENDENTE {
  color: #67748e;
}

#app .VENCIDO {
  color: red;
}

#app .INATIVO {
  color: red;
}

#app .ATIVO {
  color: #67748e !important;
}

#app .QUITADO {
  color: #67748e;
}

#app .SEPARACAO {
  color: #000080;
}

#app .ENTREGATOTAL {
  color: green;
}

#app .RETIRADO {
  color: green;
}
#app .DISPONIBILIZADO {
  color: #a0522d;
}
#app .ENTREGAPARCIAL {
  color: green;
}

#app .RECEBIDO {
  color: green;
}

#app .CONCLUIDO {
  color: rgb(9, 146, 9);
}

#app .ROTEIRIZADO {
  color: rgb(9, 146, 9);
}

#app .TRAJETOIMPARCONCLUIDO {
  color: rgb(9, 146, 9); /* Cor verde para status concluído */
  background-color: #f2f1f1a3; /* Cinza claro para roteiro ímpar */
}

#app .TRAJETOIMPAR {
  background-color: #f2f1f1a3; /* Cinza claro para roteiro ímpar */
}

#app .TRAJETOPARCONCLUIDO {
  color: rgb(9, 146, 9); /* Cor verde para status concluído */
  background-color: #8c9eba45;
}

#app .TRAJETOPAR {
  background-color: #8c9eba45;
}

.ENTREGAPENDENTE {
  color: #daa520;
}

.rounded-div {
  background-color: red;
  border-radius: 999px;
}

.card-saldo p {
  margin: 0 !important;
}

.card-saldo {
  display: grid;
  grid-template-columns: 1fr;
  background: #67748e;
  color: white;
  padding: 0.2em 2em;
  text-align: left;
  border-radius: 2px;
}
</style>
